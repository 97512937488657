import { useMemo } from 'react';

import { TelemetryQuery, TokenDistributionQuery } from 'gql';

export type NetworkStatsType = {
  finalized_blocks: number;
  active_members: number;
  transfers: number;
  shards_live: number;
  tasks_created: number;
  total_accounts: number;
  extrinsics: number;
};

type SubStatsType = {
  amount: string;
  percentage: number;
};

export type TokenStatsType = {
  totalIssuance: number;
  totalStaked: SubStatsType;
  treasury: SubStatsType;
  circulatingSupply: SubStatsType;
  vesting: SubStatsType;
};

interface useTelemetryProps {
  supportedChain?: string[];
  telemetryData: TelemetryQuery | undefined;
  tokenDistributionData: TokenDistributionQuery | undefined;
}

const useTelemetry = ({
  supportedChain,
  telemetryData,
  tokenDistributionData,
}: useTelemetryProps) => {
  const networkStats = telemetryData?.telemetry as unknown as NetworkStatsType;
  const chains = useMemo(
    () => [...new Set(supportedChain?.map((el: string) => el.split(',')[0]))],
    [supportedChain]
  );
  const tokenStats = tokenDistributionData?.tokenDistribution as unknown as TokenStatsType;
  return { networkStats, chains, tokenStats };
};
export default useTelemetry;
