import { useCallback } from 'react';

import { DEFAULT_PAGE_SIZE } from 'consts';
import { useApiAccess } from 'contexts/AccessProvider';
import { FilterType, useListMembersQuery } from 'gql';
import { useFilter, usePagination, useSortBy } from 'hooks';
import { downloadCSV, parseData } from 'utils';

import ChronicleColumns from './components/ChronicleColumns';

function useChronicleList() {
  const { sessionKey } = useApiAccess();
  const { PageController, setTotalDataCount, pageSize, pageNumber, setPageNumber } = usePagination({
    defaultPageSize: DEFAULT_PAGE_SIZE,
  });

  const { FilterController, selectedField } = useFilter(FilterType.Members, setPageNumber);

  const { sortField, sortOrder, onSort } = useSortBy();

  const {
    data: membersData,
    loading: isLoading,
    previousData,
  } = useListMembersQuery({
    variables: {
      sessionKey,
      page: { size: pageSize, number: pageNumber },
      ...(sortField && {
        sort: { field: sortField, order: sortOrder },
      }),
      filter: selectedField,
    },
    skip: selectedField.column ? !selectedField.value : false,
    onCompleted(data) {
      setTotalDataCount(data.listMembers.count);
    },
    fetchPolicy: 'cache-and-network',
  });

  const handleDownload = useCallback(() => {
    const members = membersData?.listMembers.data;
    if (!members || members.length === 0) {
      return;
    }
    const membersHeader = ['Account Id', 'Network', 'Signature', 'State', 'Shard Id', 'Heartbeat'];
    const memberRows = members.map((member) => [
      member?.account_id,
      parseData(member?.network as string),
      member?.sig,
      member?.state,
      member?.shardId,
      member?.heartbeat,
    ]);

    const fileName = `Chronicles ${members[0]?.account_id} - ${
      members[members.length - 1]?.account_id
    }`;

    downloadCSV(fileName, membersHeader, memberRows);
  }, [membersData]);
  const membersColumn = ChronicleColumns();

  return {
    PageController,
    membersData: membersData ? membersData?.listMembers.data : previousData?.listMembers.data,
    isLoading,
    pageSize,
    sortOrder,
    onSort,
    FilterController,
    handleDownload,
    membersColumn,
  };
}

export default useChronicleList;
