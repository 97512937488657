import allImgPaths from 'assets/allImgPaths';
import { Link, useLocation } from 'react-router-dom';
import { routes } from 'routes/routesConst';

import SearchBox, { SearchTypeOptions } from './SearchBox';

const ResourceNotFound = ({
  page,
  searchType,
}: {
  page: keyof typeof routes;
  searchType: SearchTypeOptions;
}) => {
  const { pathname } = useLocation();

  const [_, __, id] = pathname.split('/');

  return (
    <>
      <div className="flex flex-col gap-4 md:gap-8">
        <SearchBox defaultSearchType={'All'} />
      </div>

      <div className="flex flex-col mt-4 md:mt-8 gap-8 lg:gap-10">
        <div className="z-10 flex flex-col gap-8 sm:gap-7 justify-center items-center">
          <div className="flex flex-col items-center justify-center">
            <div>
              {page === 'blocks' ? (
                <img
                  src={allImgPaths.blockIcon}
                  alt="Block"
                  className="h-24 w-24 sm:h-32 sm:w-32 animate-spin-slow"
                />
              ) : (
                <h1 className="text-center text-[#ffffff4d] text-[120px] sm:text-[160px] font-medium leading-[normal]">
                  404
                </h1>
              )}
            </div>
            <div>
              {page === 'blocks' ? (
                <h2 className="text-white text-[32px] sm:text-5xl text-center mt-3">
                  Block {id} not finalized
                </h2>
              ) : (
                <h2 className="text-white text-[32px] sm:text-5xl text-center">
                  Resource not Found
                </h2>
              )}
            </div>
            <div className="mt-3">
              {page === 'blocks' ? (
                <span className="text-offWhite text-sm sm:text-base">
                  Oops! Requested block {id} is not finalized yet!
                </span>
              ) : (
                <span className="text-offWhite text-sm sm:text-base">
                  Oops! Requested Resource not found{' '}
                </span>
              )}
            </div>
            <Link to={routes[page]} className="cursor-pointer">
              <div className="mt-6 flex items-center gap-1">
                <span className="text-white text-base"> Go back to {page} Page </span>
                <img src={allImgPaths.rightArrowIcon} alt="right-arrow" />
              </div>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};
export default ResourceNotFound;
