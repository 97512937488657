import { Hashicon as EMEHashIcon, HashiconProps } from '@emeraldpay/hashicon-react';
import React from 'react';
import cn from '../../utils/classnames';
import { truncate, useTruncate } from '../../utils/truncateString';
import { CopyToClipboard } from '../CopyToClipboard/CopyToClipboard';
import { Tooltip } from '../Tooltip/Tooltip';

export type HashIconProps = {
  size?: HashiconProps['size'];
  value: string | null | undefined;
  className?: string;
  copyable?: boolean;
  dataToolTipId?: string;
  dataToolTipContent?: string;
  handleNavigate?: () => void;
  widthTruncateManage?: boolean;
};

export const HashIcon = React.forwardRef<HTMLDivElement, HashIconProps>(
  (
    {
      className,
      size,
      value,
      copyable,
      dataToolTipId,
      dataToolTipContent,
      handleNavigate,
      widthTruncateManage,
    },
    ref
  ) => {
    return (
      <div ref={ref} className={cn('flex gap-1 text-offWhite items-center', className)}>
        <EMEHashIcon size={size || 24} value={value!} />
        <Tooltip
          id={dataToolTipId || ''}
          content={dataToolTipContent}
          clickable
          className="!w-full sm:!w-fit !break-words !whitespace-normal !rounded-md cursor-text"
        />
        <div
          data-tooltip-id={dataToolTipId}
          data-tooltip-content={dataToolTipContent}
          onClick={() => handleNavigate && handleNavigate()}
        >
          {widthTruncateManage ? truncate(value!) : useTruncate(value!)}
        </div>
        {copyable && (
          <CopyToClipboard
            iconClasses="inline"
            icon
            id={value as string}
            content={value as string}
          />
        )}
      </div>
    );
  }
);

HashIcon.displayName = 'HashIcon';
