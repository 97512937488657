import { Tooltip } from '@analog/ui/src';
import { Skeleton } from 'components/Skeleton';
import { FontColors } from 'consts';
import { classes } from 'utils';

interface TextProps {
  extraClass?: string | undefined;
  color?: string;
  children: React.ReactNode;
  ellipsis?: boolean;
  onClick?: () => void;
  dataToolTipId?: string;
  dataToolTipContent?: string;
  toolTipClass?: string;
}

const Text = ({
  extraClass = '',
  ellipsis = true,
  children,
  color = FontColors.Gray,
  onClick,
  dataToolTipId,
  dataToolTipContent,
  toolTipClass,
  ...rest
}: TextProps) => {
  return (
    <Skeleton.Loader className="w-16 h-4" containerClassName="h-4">
      <Tooltip
        id={dataToolTipId!}
        content={dataToolTipContent}
        clickable
        className={classes(
          '!w-full sm:!w-fit !break-words !whitespace-normal !rounded-md',
          toolTipClass
        )}
      />
      <span
        className={classes(
          'text-sm',
          ellipsis && 'text-ellipsis overflow-hidden whitespace-nowrap',
          color,
          extraClass
        )}
        onClick={onClick}
        data-tooltip-id={dataToolTipId}
        {...rest}
      >
        {children}
      </span>
    </Skeleton.Loader>
  );
};

export default Text;
