import { useApiAccess } from 'contexts/AccessProvider';
import { useNominatorDetailsQuery } from 'gql';
import { useParams } from 'react-router-dom';

import NominatorData from './components/NominatorData';

const useNominatorDetails = () => {
  const { sessionKey } = useApiAccess();
  const { id } = useParams();

  const { data: nominator, loading: isLoading } = useNominatorDetailsQuery({
    variables: { address: id as string, sessionKey },
    skip: !id,
  });

  const nominatorData = NominatorData({ nominator: nominator });

  return { isLoading, nominator, nominatorData, error: !nominator?.nominatorDetails && !isLoading };
};

export default useNominatorDetails;
