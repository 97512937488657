import { Icon, Tooltip } from '@analog/ui/src';
import allImgPaths from 'assets/allImgPaths';
import { Card, SearchBox, Table } from 'components';
import { Skeleton } from 'components/Skeleton';
import { TerminologyData } from 'pages/Home/HomePage/components/Telemetry/Telemetry';
import { arrayOfSize } from 'utils';

import { useExtrinsics } from './useExtrinsics';

const ExtrinsicsList = () => {
  const {
    extrinsicsData,
    PageController,
    isLoading,
    pageSize,
    COLUMNS,
    FilterController,
    totalExtrinsic,
    handleDownload,
  } = useExtrinsics();

  return (
    <>
      <div className="flex flex-col gap-4 md:gap-8">
        <div className="flex gap-2">
          <SearchBox defaultSearchType={'All'} />
          {FilterController}
        </div>
      </div>
      <div className="flex flex-col mt-8 md:mt-[60px] gap-8 lg:gap-10">
        <Card extraClasses="!rounded-[20px] !p-6 !pb-5 flex flex-col gap-[18px]">
          <span className="text-white text-xl flex items-center">
            <Tooltip
              place="top-start"
              id={TerminologyData['extrinsic'].id}
              content={TerminologyData['extrinsic'].content}
              className="max-w-[340px] md:max-w-[540px] !text-black !rounded-lg z-10 !text-xs !px-[6px] !py-[6px] md:!px-[12px] md:!py-[10px] text-pretty"
            />
            Extrinsics
            <Skeleton.Loader>
              <span className="text-white/70 text-base pl-[6px]">({totalExtrinsic})</span>
            </Skeleton.Loader>
            <Icon
              icon={allImgPaths.infoIcon}
              iconClasses="h-[14px] w-[14px]"
              extraClasses="bg-transparent h-auto min-w-8 flex-none justify-center cursor-pointer"
              tooltipId={TerminologyData['extrinsic'].id}
            />
          </span>
          <Table
            columns={COLUMNS}
            hasHeader
            classNames={{
              row: 'grid gap-x-3 grid-cols-2 md:grid-cols-[minmax(160px,20%)_minmax(90px,15%)_minmax(150px,20%)_minmax(120px,15%)_minmax(70px,10%)_minmax(210px,10%)_minmax(15px,2%)] border-none',
              columns: 'justify-start',
            }}
            data={!isLoading ? extrinsicsData : arrayOfSize(pageSize)}
            isAccordion
            isLoading={isLoading}
            uniqueKey="id"
          />
          <Skeleton.Provider isLoading={isLoading}>
            {(isLoading || extrinsicsData?.length) && (
              <PageController onDownload={handleDownload} />
            )}
          </Skeleton.Provider>
        </Card>
      </div>
      <Tooltip id={'hash'} />
    </>
  );
};

export default ExtrinsicsList;
