import { Icon } from '@analog/ui/src';
import allImgPaths from 'assets/allImgPaths';
import { Skeleton } from 'components/Skeleton';
import TableColumns from 'components/Table/TableColumns';
import { FontColors } from 'consts';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { routes } from 'routes/routesConst';
import { ColumnsType } from 'types';
import { classes, statusColor, timeAgo } from 'utils';

const ExtrinsicsColumns = (): ColumnsType[] => {
  const navigate = useNavigate();
  return [
    {
      title: 'Extrinsic ID',
      key: 'id',
      shouldWrap: false,
      render: (data: any) => (
        <div className="flex gap-[10px] col-span-2 md:col-span-1">
          <Skeleton.Loader className="h-10 w-10 !rounded-xl" containerClassName="h-10">
            <Icon icon={allImgPaths.dataArrowsIcon} iconName="profile" />
          </Skeleton.Loader>
          <div className={'flex flex-col overflow-hidden w-full'}>
            <TableColumns
              value={data[data.columnKey]}
              classNames={{ content: 'cursor-pointer w-fit' }}
              textColor={FontColors.PURPLE}
              label="Extrinsic"
              onClick={() => {
                data && navigate(`${routes.extrinsic}/${data[data.columnKey]}`);
              }}
            />
          </div>
        </div>
      ),
    },
    {
      title: 'Hash',
      key: 'hash',
      render: (data: any) => (
        <TableColumns
          value={data[data.columnKey] || '-'}
          withTruncate
          withCopyButton
          textColor={FontColors.PURPLE}
          classNames={{ content: 'cursor-pointer', beforeIcon: 'h-4' }}
          toolTipClass="cursor-text"
          dataTooltipId={data[data.columnKey]}
          dataTooltipContent={data[data.columnKey]}
          onClick={() => navigate(`${routes.extrinsic}/${data[data.columnKey]}`)}
        />
      ),
    },
    {
      title: 'Age',
      key: 'created_at',
      render: (data: any) => (
        <TableColumns
          value={data[data.columnKey] ? timeAgo(new Date(data[data.columnKey])) : '-'}
          classNames={{ content: 'text-white', beforeIcon: 'h-4' }}
          dataTooltipId={data[data.columnKey]}
          dataTooltipContent={moment.utc(data[data.columnKey]).format('YYYY-MM-DD hh:mm:ss (UTC)')}
          toolTipClass="!w-fit"
        />
      ),
    },
    {
      title: 'Result',
      key: 'result',
      render: (data: any) => (
        <Skeleton.Loader className="h-4 w-[70px]" containerClassName="h-4">
          <div
            className={classes(
              `rounded-3xl py-[2px] px-[7px] inline-flex text-ellipsis overflow-hidden whitespace-nowrap w-fit`,
              statusColor(data.result)
            )}
          >
            <span
              className={
                'text-[10px] rounded-3xl uppercase leading-[18px] text-ellipsis overflow-hidden whitespace-nowrap'
              }
            >
              {data.result ? data.result : '-'}
            </span>
          </div>
        </Skeleton.Loader>
      ),
    },
    {
      title: 'Action',
      key: 'section',
      render: (data: any) => (
        <TableColumns
          value={`${data[data.columnKey]} (${data['method']})`}
          classNames={{
            content: 'text-white capitalize w-full lg:w-[196px]',
            base: 'md:justify-start w-full',
          }}
          dataTooltipId={data[data.columnKey]}
          dataTooltipContent={`${data[data.columnKey]} (${data['method']})`}
          toolTipClass="!w-fit !capitalize"
        />
      ),
    },
    {
      title: '',
      key: 'parameters',
      render: () => <></>,
    },
  ];
};

export default ExtrinsicsColumns;
