import { useMemo } from 'react';

import allImgPaths from 'assets/allImgPaths';

export interface StatusStepperProps {
  currentPhase: string;
}

const Status = [
  { label: 'submitted', icon: allImgPaths.greenTickIcon },
  { label: 'sign', icon: allImgPaths.signedIcon },
  { label: 'write', icon: allImgPaths.processIcon },
  { label: 'read', icon: allImgPaths.tickCircleIcon },
];

export const useStatusStepper = ({ currentPhase }: StatusStepperProps) => {
  const updatedStatus = useMemo(() => {
    const stepIndex = Status.findIndex((step) => step.label === currentPhase.toLowerCase());
    return Status.map((step, index) => ({
      ...step,
      isCompleted: index <= stepIndex,
      isCurrent: index === stepIndex,
      isFuture: index > stepIndex,
    }));
  }, [currentPhase]);
  return { updatedStatus };
};
