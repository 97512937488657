import { HashIcon, Icon } from '@analog/ui';
import { formatBalance } from '@polkadot/util';
import allImgPaths from 'assets/allImgPaths';
import { AnalogIcon, Text } from 'components';
import { FontColors } from 'consts';
import { NominatorDetailsQuery } from 'gql';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { routes } from 'routes/routesConst';
import { formatBalanceOptionRaw, newFormatBalance, timeAgo } from 'utils';

interface NominatorDataProps {
  nominator: NominatorDetailsQuery | undefined;
}

const NominatorData = ({ nominator }: NominatorDataProps) => {
  const navigate = useNavigate();
  return [
    {
      icon: <Icon icon={allImgPaths.globalSearchIcon} />,
      label: 'Stash',
      value: (
        <HashIcon
          size={20}
          copyable
          value={nominator?.nominatorDetails?.address}
          className="text-pink cursor-pointer"
          dataToolTipId={`${nominator?.nominatorDetails?.address}-stash` as string}
          dataToolTipContent={nominator?.nominatorDetails?.address as string}
          handleNavigate={() =>
            navigate(`${routes.account}/${nominator?.nominatorDetails?.address}`)
          }
        />
      ),
    },
    {
      icon: <Icon icon={allImgPaths.globalSearchIcon} />,
      label: 'Controller',
      value: (
        <HashIcon
          size={20}
          copyable
          value={nominator?.nominatorDetails?.controller}
          className="text-pink cursor-pointer"
          dataToolTipId={`${nominator?.nominatorDetails?.controller}-controller` as string}
          dataToolTipContent={nominator?.nominatorDetails?.controller as string}
          handleNavigate={() =>
            navigate(`${routes.account}/${nominator?.nominatorDetails?.controller}`)
          }
        />
      ),
    },
    {
      icon: <Icon icon={allImgPaths.dollarIcon} />,
      label: 'Bonded',
      value: (
        <div className="flex flex-row items-center gap-1">
          <AnalogIcon className="w-5 h-5" />
          <Text
            extraClass="text-white text-sm"
            dataToolTipId={nominator?.nominatorDetails?.bonded || ''}
            dataToolTipContent={`${formatBalance(
              nominator?.nominatorDetails?.bonded || '0',
              formatBalanceOptionRaw
            )} ANLOG`}
            toolTipClass="!w-fit"
          >
            {newFormatBalance(nominator?.nominatorDetails?.bonded || '0')} ANLOG
          </Text>
        </div>
      ),
    },
    {
      icon: <Icon icon={allImgPaths.tickCircleIcon} />,
      label: 'Status',
      value: (
        <div className="flex items-center">
          <Text color={FontColors.WHITE}>{nominator?.nominatorDetails?.status}</Text>
          {nominator?.nominatorDetails?.status === 'ACTIVE' && (
            <Icon icon={allImgPaths.greenTickCircleIcon} extraClasses="bg-transparent h-0" />
          )}
        </div>
      ),
    },
    {
      icon: <Icon icon={allImgPaths.clockIcon} />,
      label: 'Created At',
      value: nominator?.nominatorDetails?.created_at
        ? `${timeAgo(new Date(nominator?.nominatorDetails?.created_at))} (${moment
            .utc(nominator?.nominatorDetails?.created_at)
            .format('MMM-D-YYYY h:mm:ss A +UTC')})`
        : '-',
      enable: !!nominator?.nominatorDetails?.created_at,
    },
    {
      icon: <Icon icon={allImgPaths.clockIcon} />,
      label: 'Updated At',
      value: nominator?.nominatorDetails?.updated_at
        ? `${timeAgo(new Date(nominator?.nominatorDetails?.updated_at))} (${moment
            .utc(nominator?.nominatorDetails?.updated_at)
            .format('MMM-D-YYYY h:mm:ss A +UTC')})`
        : '-',
      enable: !!nominator?.nominatorDetails?.updated_at,
    },
  ];
};
export default NominatorData;
