import React, { useState } from 'react';

import { Icon } from '@analog/ui/src';
import allImgPaths from 'assets/allImgPaths';
import { Card } from 'components';
import { Skeleton } from 'components/Skeleton';
import { TelemetryQuery, TokenDistributionQuery } from 'gql';
import { routes } from 'routes/routesConst';
import { getIcon } from 'utils';

import { DonutChart, NetworkStatsUI } from './components';
import useTelemetry, { NetworkStatsType } from './useTelemetry';

export const TerminologyData = {
  transfer: {
    id: 'transfer',
    content:
      'Transfers are Transfer extrinsics that involve the signed movement of tokens between accounts, updating the balances on the blockchain and typically requiring a fee to be paid by the sender.',
  },
  extrinsic: {
    id: 'extrinsic',
    content: `An extrinsic in a Substrate-based blockchain is any external input, such as a signed transaction, an unsigned operation, or inherent data, that can modify the blockchain's state when included in a block.`,
  },
};

export const NetworkStats: {
  icon?: JSX.Element;
  title: string;
  key: keyof NetworkStatsType;
  tooltipId?: string;
  tooltipContent?: string;
  link?: string;
}[] = [
  {
    icon: (
      <Icon
        icon={allImgPaths.blockIcon}
        iconClasses="animate-spin-slow h-6 w-6"
        extraClasses="bg-transparent h-auto min-w-8 flex-none justify-start"
      />
    ),
    title: 'Finalized Blocks',
    key: 'finalized_blocks',
    link: routes.blocks,
  },
  {
    icon: (
      <Icon
        icon={allImgPaths.transferIcon}
        iconClasses="rotate-90 h-6 w-6"
        extraClasses="bg-transparent h-auto min-w-8 flex-none justify-start"
      />
    ),
    title: 'Transfers',
    key: 'transfers',
    tooltipId: TerminologyData['transfer'].id,
    tooltipContent: TerminologyData['transfer'].content,
    link: routes.transfers,
  },
  {
    icon: (
      <Icon
        icon={allImgPaths.signIcon}
        iconClasses="h-6 w-6"
        extraClasses="bg-transparent h-auto min-w-8 flex-none justify-start"
      />
    ),
    title: 'Extrinsics',
    key: 'extrinsics',
    tooltipId: TerminologyData['extrinsic'].id,
    tooltipContent: TerminologyData['extrinsic'].content,
    link: routes.extrinsics,
  },
  {
    icon: (
      <Icon
        icon={allImgPaths.holderIcon}
        iconClasses="h-6 w-6"
        extraClasses="bg-transparent h-auto min-w-8 flex-none justify-start"
      />
    ),
    title: 'Total Accounts',
    key: 'total_accounts',
    link: routes.accounts,
  },
  {
    icon: (
      <Icon
        icon={allImgPaths.stakeIcon}
        iconClasses="h-6 w-6"
        extraClasses="bg-transparent h-auto min-w-8 flex-none justify-start"
      />
    ),
    title: 'Active Chronicles',
    key: 'active_members',
    link: routes.chronicles,
  },
  {
    icon: (
      <Icon
        icon={allImgPaths.inflationIcon}
        iconClasses="h-6 w-6"
        extraClasses="bg-transparent h-auto min-w-8 flex-none justify-start"
      />
    ),
    title: 'Shards Live',
    key: 'shards_live',
    link: routes.shards,
  },
  {
    icon: (
      <Icon
        icon={allImgPaths.parametersIcon}
        iconClasses="h-6 w-6"
        extraClasses="bg-transparent h-auto min-w-8 flex-none justify-start"
      />
    ),
    title: 'Tasks Created',
    key: 'tasks_created',
    link: routes.gmp,
  },
];
export const AnalogNetworkStats = (stats: NetworkStatsType) => {
  const [hoverPositions, setHoverPositions] = useState<{ x: number; y: number }[]>(
    Array.from({ length: NetworkStats.length }, () => ({ x: 0, y: 0 }))
  );
  const [isHovering, setIsHovering] = useState<boolean[]>(
    Array.from({ length: NetworkStats.length }, () => false)
  );

  const handleMouseMove = (index: number, e: React.MouseEvent<HTMLDivElement>) => {
    const containerRect = e.currentTarget.getBoundingClientRect();
    const x = e.clientX - containerRect.left;
    const y = e.clientY - containerRect.top;

    const newPositions = [...hoverPositions];
    newPositions[index] = { x, y };
    setHoverPositions(newPositions);
  };

  const handleMouseEnter = (index: number) => {
    const newHoverState = [...isHovering];
    newHoverState[index] = true;
    setIsHovering(newHoverState);
  };

  const handleMouseLeave = (index: number) => {
    const newHoverState = [...isHovering];
    newHoverState[index] = false;
    setIsHovering(newHoverState);
  };
  // Analog Network Stats
  return (
    <div className="flex gap-4 flex-col">
      <span className="md:text-2xl text-xl text-white">Chain Data</span>
      <div className="gap-4 lg:gap-6 justify-between grid grid-cols-2  xl:grid-cols-5 grid-flow-row [&>*:nth-child(5)]:col-span-2 xl:[&>*:nth-child(5)]:col-span-1">
        {NetworkStats.map(({ icon, title, key }, index) => (
          <Card key={index} hoverEffect extraClasses="overflow-hidden h-max">
            <div
              className="flex flex-col h-full relative"
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={() => handleMouseLeave(index)}
              onMouseMove={(e) => handleMouseMove(index, e)}
            >
              <div
                className="absolute h-[70px] w-[70px] rounded-[38px] bg-[#ff00f566] bottom-7 right-5 blur-[25px] hidden group-hover:flex z-[-1]"
                style={{ top: hoverPositions[index].y - 25, left: hoverPositions[index].x - 25 }}
              ></div>
              <div>
                <p className="text-white text-sm lg:text-base whitespace-nowrap flex items-center gap-2">
                  {icon}
                  <span>{title}</span>
                </p>
                <div className="flex gap-2 flex-col h-full">
                  <div className="flex items-end flex-wrap">
                    <Skeleton.Loader className="w-20 h-10" containerClassName="h-10">
                      <div className="relative overflow-hidden">
                        <div
                          className="absolute animate-slide-in text-[32px] lg:text-[40px] text-white font-medium leading-9 lg:leading-[56px]"
                          key={`title-${new Number(stats[key]).toLocaleString()}`}
                        >
                          {new Number(stats[key]).toLocaleString()}
                        </div>
                        <div
                          className="animate-fade-out text-[32px] lg:text-[40px] text-white font-medium leading-9 lg:leading-[56px]"
                          key={`title-prev-${new Number(stats[key]).toLocaleString()}`}
                        >
                          {new Number(stats[key]).toLocaleString()}
                        </div>
                      </div>
                    </Skeleton.Loader>
                  </div>
                </div>
              </div>
            </div>
          </Card>
        ))}
      </div>
    </div>
  );
};
export const ChainsSupported = ({ chains }: { chains: string[] }) => {
  return (
    <div className="flex gap-4 flex-col">
      <Skeleton.Loader className="w-[10%] h-8">
        <span className="md:text-2xl text-xl text-white">Chains Supported</span>
      </Skeleton.Loader>
      <div className="gap-4 lg:gap-6 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5">
        {chains.map((chain, index) => (
          <Card extraClasses="!py-[14px] !pl-4 rounded-2xl" hoverEffect key={index}>
            <div className="flex gap-[9px]">
              <Skeleton.Loader className="w-10 h-10" containerClassName="h-10">
                <img src={getIcon(chain)} alt={chain} className="w-10 h-10" />
              </Skeleton.Loader>
              <div className="flex flex-col">
                <Skeleton.Loader className="w-[70px] h-3" containerClassName="h-3">
                  <span className="text-offWhite text-xs">Chain</span>
                </Skeleton.Loader>
                <Skeleton.Loader className="w-[100px] h-5 mt-2" containerClassName="h-5">
                  <span className="text-white capitalize">{chain}</span>
                </Skeleton.Loader>
              </div>
            </div>
          </Card>
        ))}
      </div>
    </div>
  );
};

type TelemetryProps = {
  telemetryData: TelemetryQuery | undefined;
  isTelemetryLoading?: boolean;
  supportedChain?: string[];
  isSupportedChainLoading?: boolean;
  tokenDistributionData: TokenDistributionQuery | undefined;
  tokenDistributionLoading: boolean;
};

const Telemetry = ({
  telemetryData,
  isTelemetryLoading,
  supportedChain,
  isSupportedChainLoading,
  tokenDistributionData,
}: TelemetryProps) => {
  const { networkStats, chains, tokenStats } = useTelemetry({
    telemetryData,
    supportedChain,
    tokenDistributionData,
  });

  return (
    <>
      <Skeleton.Provider isLoading={isTelemetryLoading}>
        <div className="sm:flex gap-6">
          <NetworkStatsUI {...networkStats} />
          <DonutChart {...tokenStats} />
        </div>
      </Skeleton.Provider>

      <Skeleton.Provider isLoading={isSupportedChainLoading}>
        <ChainsSupported
          chains={!isSupportedChainLoading ? (supportedChain?.length ? chains : []) : ['']}
        />
      </Skeleton.Provider>
    </>
  );
};

export default Telemetry;
