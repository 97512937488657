import { useWindowSize } from './useWindowSize';

export function useTruncate(
  value: string | undefined,
  textLength: number | undefined = undefined
): string {
  const {
    windowSize: { width },
  } = useWindowSize();
  if (!textLength) {
    if (width >= 1440) {
      return value!;
    } else if (width < 1441 && width > 920) {
      return value!;
    } else if (width < 921 && width > 766) {
      textLength = 14;
    } else if (width < 767 && width > 300) {
      textLength = 6;
    } else if (width < 301) {
      textLength = 5;
    }
  }
  return value
    ? value.length > textLength! * 2
      ? `${value.substring(0, textLength)}...${value.substring(value.length - textLength!)}`
      : value
    : '';
}

export function truncate(value: string | undefined, sideLength = 6): string {
  return value
    ? value.length > sideLength * 2
      ? `${value.substring(0, sideLength)}...${value.substring(value.length - sideLength)}`
      : value
    : '';
}
