import { Icon } from '@analog/ui/src';
import { formatBalance } from '@polkadot/util';
import allImgPaths from 'assets/allImgPaths';
import { AnalogIcon, Text } from 'components';
import { Skeleton } from 'components/Skeleton';
import TableColumns from 'components/Table/TableColumns';
import { FontColors } from 'consts';
import { useNavigate } from 'react-router-dom';
import { routes } from 'routes/routesConst';
import { ColumnData, ColumnsType } from 'types';
import { formatBalanceOptionHighest } from 'utils';

const NominatorsColumns = (): ColumnsType[] => {
  const navigate = useNavigate();
  return [
    {
      title: 'Account',
      key: 'nominatorAddress',
      render(data: ColumnData) {
        return (
          <Skeleton.Loader className="flex md:justify-end" containerClassName="flex">
            <div className="flex gap-[10px] col-span-2 md:col-span-1 cursor-pointer items-center">
              <Icon icon={allImgPaths.profileIcon} />
              <div className={'flex flex-col overflow-hidden w-full'}>
                <TableColumns
                  value={data[data.columnKey]}
                  withTruncate
                  withCopyButton
                  width="100%"
                  toolTipClass="!cursor-text"
                  textColor={FontColors.PURPLE}
                  dataTooltipId={data[data.columnKey]}
                  dataTooltipContent={data[data.columnKey]}
                  onClick={() =>
                    data[data.columnKey] && navigate(`${routes.nominator}/${data[data.columnKey]}`)
                  }
                />
              </div>
            </div>
          </Skeleton.Loader>
        );
      },
    },
    {
      title: 'Voted',
      key: 'stakedAmount',
      render(data: ColumnData) {
        return (
          <Skeleton.Loader className="flex md:justify-end" containerClassName="flex">
            <div className="flex flex-row items-center gap-1">
              <AnalogIcon className="w-5 h-5" />
              <Text
                extraClass="text-white text-sm"
                dataToolTipId={`${data[data.columnKey]?.toFixed()}` || '0'}
                dataToolTipContent={`${formatBalance(
                  data[data.columnKey] || '0',
                  formatBalanceOptionHighest
                )} ANLOG`}
                toolTipClass="!w-fit"
              >
                {formatBalance(data[data.columnKey] || '0', formatBalanceOptionHighest)} ANLOG
              </Text>
            </div>
          </Skeleton.Loader>
        );
      },
    },
    {
      title: 'Share',
      key: 'share',
      render(data: ColumnData) {
        return (
          <Skeleton.Loader className="flex md:justify-end" containerClassName="flex">
            <Text extraClass="text-white text-sm">{data[data.columnKey]} %</Text>
          </Skeleton.Loader>
        );
      },
    },
  ];
};

export default NominatorsColumns;
