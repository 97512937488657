import { useMemo, useState } from 'react';

import { Pagination } from 'components';

interface UsePaginationParams {
  defaultPageSize: number;
}

function usePagination({ defaultPageSize }: UsePaginationParams) {
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [totalDataCount, setTotalDataCount] = useState(0);

  const handleSetPageSize = (size: number) => {
    setPageSize(size);
    setPageNumber(1);
  };

  const PageController = useMemo(
    () => (extraProps: { onDownload?: () => void }) =>
      (
        <Pagination
          totalCount={totalDataCount}
          pageNumber={pageNumber}
          setPageSize={handleSetPageSize}
          defaultSize={pageSize}
          setPageNumber={setPageNumber}
          {...extraProps}
        />
      ),
    [pageNumber, totalDataCount, pageSize]
  );

  return { pageNumber, setPageNumber, pageSize, setTotalDataCount, PageController };
}

export default usePagination;
