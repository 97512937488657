import { useState } from 'react';

import { useWindowSize } from '@analog/ui/src/utils/useWindowSize';
import ExtrinsicsColumns from 'components/common/ExtrinsicsColumns';
import { DEFAULT_PAGE_SIZE } from 'consts';
import { useApiAccess } from 'contexts/AccessProvider';
import { useGetExtrinsicsQuery, useListEventsQuery, useListLogsQuery } from 'gql';
import { usePagination } from 'hooks';

import { useBlockDetails } from '../useBlockDetails';

import EventsColumns from './EventsColumns';
import LogsColumns from './LogsColumns';

type TabType = 'extrinsics' | 'events' | 'logs';

const useBlockrelationalData = () => {
  const { sessionKey } = useApiAccess();
  const [selectedTab, setSelectedTab] = useState<TabType>('extrinsics');

  const tabs: TabType[] = ['extrinsics', 'events', 'logs'];
  const { blockDetailsData } = useBlockDetails();
  const { isMobile } = useWindowSize();

  const {
    pageNumber: extrinsicsPageNumber,
    pageSize: extrinsicsPageSize,
    setTotalDataCount: setExtrinsicTotalPagesCount,
    PageController: ExtrinsicPageController,
  } = usePagination({ defaultPageSize: DEFAULT_PAGE_SIZE });

  const { data: extrinsicsData, loading: isExtrinsicsLoading } = useGetExtrinsicsQuery({
    variables: {
      blockNumber: blockDetailsData?.blockDetails?.number.toString(),
      sessionKey,
      page: {
        number: extrinsicsPageNumber,
        size: extrinsicsPageSize,
      },
    },
    onCompleted(data) {
      setExtrinsicTotalPagesCount(data.getExtrinsics.count);
    },
    skip: !blockDetailsData?.blockDetails?.number,
    fetchPolicy: 'cache-first',
  });

  const {
    pageNumber: eventPageNumber,
    pageSize: eventPageSize,
    setTotalDataCount: setEventTotalPagesCount,
    PageController: EventPageController,
  } = usePagination({ defaultPageSize: DEFAULT_PAGE_SIZE });

  const { data: eventData, loading: isEventLoading } = useListEventsQuery({
    variables: {
      blockNumber: blockDetailsData?.blockDetails?.number.toString(),
      sessionKey,
      page: {
        number: eventPageNumber,
        size: eventPageSize,
      },
    },
    onCompleted(data) {
      setEventTotalPagesCount(data.listEvents.count);
    },
    skip: selectedTab !== 'events',
    fetchPolicy: 'cache-first',
  });

  const {
    pageNumber: logsPageNumber,
    pageSize: logsPageSize,
    setTotalDataCount: setLogsTotalPagesCount,
    PageController: LogsPageController,
  } = usePagination({ defaultPageSize: DEFAULT_PAGE_SIZE });

  const { data: logsData, loading: isLogsLoading } = useListLogsQuery({
    variables: {
      blockNumber: blockDetailsData?.blockDetails?.number,
      sessionKey,
      page: {
        number: logsPageNumber,
        size: logsPageSize,
      },
    },
    onCompleted(data) {
      data.listLogs?.count && setLogsTotalPagesCount(data.listLogs.count);
    },
    skip: selectedTab !== 'logs',
    fetchPolicy: 'cache-first',
  });

  const EXTRINSICS_COLUMNS = ExtrinsicsColumns();

  const EVENTS_COLUMNS = EventsColumns({ isMobile });

  const LOGS_COLUMNS = LogsColumns();

  const tableData = {
    extrinsics: {
      columns: EXTRINSICS_COLUMNS,
      data: extrinsicsData?.getExtrinsics,
      isLoading: isExtrinsicsLoading,
      count: extrinsicsData?.getExtrinsics.count,
      PageController: <ExtrinsicPageController />,
      pageSize: extrinsicsPageSize,
      keys: ['id', 'method'],
    },
    events: {
      columns: EVENTS_COLUMNS,
      data: eventData?.listEvents,
      isLoading: isEventLoading,
      count: eventData?.listEvents?.count,
      PageController: <EventPageController />,
      pageSize: eventPageSize,
      keys: ['eventId', 'extrinsicId'],
    },
    logs: {
      columns: LOGS_COLUMNS,
      data: logsData?.listLogs,
      isLoading: isLogsLoading,
      count: logsData?.listLogs?.count,
      PageController: <LogsPageController />,
      pageSize: logsPageSize,
      keys: ['index', 'type'],
    },
  };

  return { tableData, selectedTab, setSelectedTab, tabs };
};

export default useBlockrelationalData;
