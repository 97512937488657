import { Tooltip } from '@analog/ui/src';
import { Card, SearchBox, Table } from 'components';
import { Skeleton } from 'components/Skeleton';
import { SortColumnsAccount, arrayOfSize } from 'utils';

import useAccountList from './useAccountList';

const AccountList = () => {
  const {
    PageController,
    accountData,
    pageSize,
    isLoading,
    sortOrder,
    onSort,
    accountColumn,
    handleDownload,
  } = useAccountList();

  return (
    <>
      <div className="flex flex-col gap-4 md:gap-8 ">
        <SearchBox defaultSearchType={'All'} />
      </div>
      <div className="mt-8 md:mt-[60px]">
        <Card extraClasses="flex gap-4 md:gap-[22px] flex-col">
          <Skeleton.Loader className="h-7 w-[100px]">
            <span className="text-white text-xl">Accounts</span>
          </Skeleton.Loader>
          <Table
            columns={accountColumn}
            hasHeader
            data={!isLoading ? accountData : arrayOfSize(pageSize)}
            classNames={{
              row: 'grid gap-x-2 grid-cols-2 md:grid-cols-[minmax(110px,50%)_minmax(90px,20%)_minmax(130px,30%)]',
              columns: 'justify-start',
            }}
            onSort={onSort}
            sortOrder={sortOrder}
            sortOptions={SortColumnsAccount}
            isLoading={isLoading}
          />
          {accountData?.length && <PageController onDownload={handleDownload} />}
        </Card>
      </div>
      <Tooltip id={'hash'} />
    </>
  );
};

export default AccountList;
