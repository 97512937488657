import { useMemo } from 'react';

import { TaskDetailsQuery } from 'gql';
import { useWindowSize } from 'hooks';
import { useNavigate } from 'react-router-dom';

export interface TaskDataProps {
  taskDetailsData: TaskDetailsQuery | undefined;
}

export const useTaskData = ({ taskDetailsData }: TaskDataProps) => {
  const navigate = useNavigate();
  const { width } = useWindowSize();
  const taskPhase = useMemo(() => {
    return taskDetailsData?.taskDetails?.phase?.toLowerCase();
  }, [taskDetailsData?.taskDetails?.phase]);
  return { navigate, width, taskPhase };
};
