import { formatBalance } from '@polkadot/util';
import { TooltipItem } from 'chart.js';
import { formatBalanceOptionRaw } from 'utils';
export type StakingChartStatType = {
  totalStake: number;
  validatorStakeTotal: number;
  nominatorStakeTotal: number;
};

export const useStakingChart = ({ telemetryData }: { telemetryData: StakingChartStatType }) => {
  const validatorStake = formatBalance(telemetryData?.validatorStakeTotal, formatBalanceOptionRaw);
  const nominatorStake = formatBalance(telemetryData?.nominatorStakeTotal, formatBalanceOptionRaw);
  const totalStake = formatBalance(telemetryData?.totalStake, formatBalanceOptionRaw);

  const toNumber = (balance: string) => Number(balance.replace(/,/g, '').trim());

  const data = {
    labels: ['Validator Stake', 'Nominator Stake'],
    datasets: [
      {
        data: [toNumber(validatorStake), toNumber(nominatorStake)],
        backgroundColor: ['#F0BAFF', '#C763FF'],
        borderWidth: 0,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    cutout: '80%',
    plugins: {
      legend: {
        display: false,
      },

      tooltip: {
        callbacks: {
          label: (tooltipItem: TooltipItem<'doughnut'>) => {
            const value = tooltipItem.raw as number;
            const percentage = ((value / toNumber(totalStake)) * 100).toFixed(2);
            return ` ${percentage}%`;
          },
        },
      },
    },
  };
  return {
    data,
    options,
    validatorStake: telemetryData?.validatorStakeTotal,
    nominatorStake: telemetryData?.nominatorStakeTotal,
    totalStake: telemetryData?.totalStake,
  };
};;
