import { Card, Heading, SearchBox, Table } from 'components';
import { Skeleton } from 'components/Skeleton';
import { arrayOfSize } from 'utils';

import useEvents from './useEvents';

function EventList() {
  const { PageController, isLoading, pageSize, eventsData, eventsCount, COLUMNS } = useEvents();

  return (
    <>
      <Skeleton.Provider isLoading={isLoading}>
        <div className="flex flex-col gap-4 md:gap-8">
          <Heading title={`Event History(${eventsCount})`} />
          <SearchBox defaultSearchType={'Events'} />
        </div>
      </Skeleton.Provider>

      <div className="flex flex-col mt-8 md:mt-[60px] gap-8 lg:gap-10">
        <Card extraClasses="!rounded-[20px] !p-5 flex flex-col gap-[18px]">
          <Table
            columns={COLUMNS}
            hasHeader
            data={!isLoading ? eventsData : arrayOfSize(pageSize)}
            classNames={{
              base: 'w-full',
              row: 'grid gap-x-2 grid-cols-2 md:grid-cols-[minmax(100px,30%)_minmax(100px,30%)_minmax(100px,30%)_minmax(100px,10%)] border-none',
            }}
            isAccordion
            isLoading={isLoading}
          />
          <Skeleton.Provider isLoading={isLoading}>
            {(isLoading || eventsData?.length) && <PageController />}
          </Skeleton.Provider>
        </Card>
      </div>
    </>
  );
}

export default EventList;
