import { DetailsList } from '@analog/ui';
import { ResourceNotFound, SearchBox } from 'components';

import NominatorRelationData from './components/NominatorRelationData';
import useNominatorDetails from './useNominatorDetails';

const NominatorDetails = () => {
  const { isLoading, nominatorData, error } = useNominatorDetails();

  if (error) {
    return <ResourceNotFound page={'nominator'} searchType={'Validators'} />;
  }

  return (
    <>
      <div className="flex flex-col gap-4 md:gap-8">
        <SearchBox defaultSearchType={'All'} />
      </div>
      <div className="flex flex-col mt-8 md:mt-[60px] gap-8 lg:gap-10">
        <DetailsList title={'Nominator Info'} details={nominatorData} isLoading={isLoading} />
        <NominatorRelationData />
      </div>
    </>
  );
};

export default NominatorDetails;
