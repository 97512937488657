import { Icon } from '@analog/ui/src';
import { formatBalance } from '@polkadot/util';
import allImgPaths from 'assets/allImgPaths';
import { Text } from 'components';
import { Skeleton } from 'components/Skeleton';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { routes } from 'routes/routesConst';
import { ColumnData } from 'types';
import { formatBalanceOptionRaw, newFormatBalance, timeAgo } from 'utils';

const LatestTransfersColumn = () => {
  const navigate = useNavigate();
  return [
    {
      title: 'id',
      key: 'extrinsicId',
      shouldWrap: false,

      render: (data: ColumnData) => (
        <div className="flex gap-[10px] col-span-1">
          <div className={'flex flex-col overflow-hidden'}>
            <Skeleton.Loader className="lg:w-[100px] w-[50px]" containerClassName="h-5">
              <p className="text-sm">
                Extrinsic#{'  '}
                <Text
                  extraClass="text-pink text-base cursor-pointer w-fit"
                  onClick={() => {
                    navigate(`${routes.extrinsic}/${data[data.columnKey]}`);
                  }}
                >
                  {data[data.columnKey]}
                </Text>
              </p>
            </Skeleton.Loader>
            <Skeleton.Loader className="lg:w-[100px] w-[50px]" containerClassName="h-5">
              <div className="sm:flex gap-[10px]">
                <p className="text-sm flex gap-[10px] text-[#9D9D9D]">
                  <span className="text-sm text-[#9D9D9D]">From</span>
                  <Text
                    extraClass="text-pink text-sm flex items-center cursor-pointer"
                    onClick={() => navigate(`${routes.account}/${data.from}`)}
                    dataToolTipId={data.from}
                    dataToolTipContent={data.from}
                  >
                    {data.from
                      ? data.from.substring(0, 6) +
                        '...' +
                        data.from.substring(data.from.length - 6)
                      : '-'}
                  </Text>
                </p>
                <p className="text-sm flex gap-[10px] text-[#9D9D9D]">
                  <span className="text-sm text-[#9D9D9D]">To</span>
                  <Text
                    extraClass="text-pink text-sm flex items-center cursor-pointer"
                    onClick={() => navigate(`${routes.account}/${data.to}`)}
                    dataToolTipId={data.to}
                    dataToolTipContent={data.to}
                  >
                    {data.to
                      ? data.to.substring(0, 6) + '...' + data.to.substring(data.from.length - 6)
                      : '-'}
                  </Text>
                </p>
              </div>
            </Skeleton.Loader>
          </div>
        </div>
      ),
    },

    {
      title: 'Type',
      key: 'amount',
      render: (data: ColumnData) => (
        <div className={'flex flex-col overflow-hidden'}>
          <Skeleton.Loader className="lg:w-[90px] w-[50px]" containerClassName="h-5">
            <div className="flex items-center">
              <Text
                extraClass="text-white text-sm"
                dataToolTipId={data[data.columnKey]}
                dataToolTipContent={`${formatBalance(
                  data[data.columnKey],
                  formatBalanceOptionRaw
                )} ANLOG`}
                toolTipClass="!w-fit"
              >
                {newFormatBalance(data[data.columnKey] || '0')} ANLOG
              </Text>
              <Icon icon={allImgPaths.greenTickCircleIcon} extraClasses="bg-transparent h-0" />
            </div>
            <Text
              extraClass="text-[#9D9D9D] text-sm"
              dataToolTipId={data.created_at}
              dataToolTipContent={moment.utc(data.created_at).format('YYYY-MM-DD hh:mm:ss (UTC)')}
              toolTipClass="!w-fit"
            >
              {data.created_at && timeAgo(new Date(data.created_at))}
            </Text>
          </Skeleton.Loader>
        </div>
      ),
    },
  ];
};
export default LatestTransfersColumn;
