import { useState } from 'react';

import RewardsColumns from 'components/common/RewardsColumns';
import { DEFAULT_PAGE_SIZE } from 'consts';
import { useApiAccess } from 'contexts/AccessProvider';
import { useGetEraQuery, useGetNominatorsQuery, useGetStakingRewardQuery } from 'gql';
import { usePagination } from 'hooks';

import useValidatorDetails from '../useValidatorDetails';

import ErasColumns from './ErasColumns';
import NominatorsColumns from './NominatorsColumns';

type TabType = 'nominators' | 'eras' | 'rewards';

export const useValidationRelationData = () => {
  const { sessionKey } = useApiAccess();
  const [selectedTab, setSelectedTab] = useState<TabType>('nominators');
  const tabs: TabType[] = ['nominators', 'eras', 'rewards'];
  const { validator } = useValidatorDetails();

  const {
    PageController: NominatorPageController,
    setTotalDataCount: setNominatorTotalDataCount,
    pageSize: NominatorPageSize,
    pageNumber: NominatorPageNumber,
  } = usePagination({
    defaultPageSize: DEFAULT_PAGE_SIZE,
  });

  const { data: nominatorData, loading: isNominatorLoading } = useGetNominatorsQuery({
    variables: {
      sessionKey,
      validator: validator?.validatorDetails?.address as string,
      page: { size: NominatorPageSize, number: NominatorPageNumber },
    },
    onCompleted(data) {
      data.getNominators && setNominatorTotalDataCount(data.getNominators?.count);
    },
    skip: !validator?.validatorDetails?.address,
    fetchPolicy: 'cache-first',
  });

  const {
    PageController: EraPageController,
    setTotalDataCount: setEraTotalDataCount,
    pageSize: EraPageSize,
    pageNumber: EraPageNumber,
  } = usePagination({
    defaultPageSize: DEFAULT_PAGE_SIZE,
  });

  const { data: eraData, loading: isEraLoading } = useGetEraQuery({
    variables: {
      sessionKey,
      validator: validator?.validatorDetails?.address,
      page: { size: EraPageSize, number: EraPageNumber },
    },
    onCompleted(data) {
      data.getEra && setEraTotalDataCount(data.getEra?.count);
    },
    skip: selectedTab !== 'eras',
    fetchPolicy: 'cache-first',
  });

  const {
    PageController: RewardPageController,
    setTotalDataCount: setRewardTotalDataCount,
    pageSize: RewardPageSize,
    pageNumber: RewardPageNumber,
  } = usePagination({
    defaultPageSize: DEFAULT_PAGE_SIZE,
  });

  const { data: rewardData, loading: isRewardLoading } = useGetStakingRewardQuery({
    variables: {
      sessionKey,
      validator: validator?.validatorDetails?.address,
      page: { size: RewardPageSize, number: RewardPageNumber },
    },
    onCompleted(data) {
      data.getStakingReward && setRewardTotalDataCount(data.getStakingReward?.count);
    },
    skip: selectedTab !== 'rewards',
    fetchPolicy: 'cache-first',
  });

  const NOMINATORS_COLUMN = NominatorsColumns();
  const ERAS_COLUMN = ErasColumns();
  const REWARDS_COLUMN = RewardsColumns();

  const tableData = {
    nominators: {
      columns: NOMINATORS_COLUMN,
      data: nominatorData?.getNominators,
      isLoading: isNominatorLoading,
      count: nominatorData?.getNominators?.count,
      PageController: <NominatorPageController />,
      pageSize: NominatorPageSize,
    },
    eras: {
      columns: ERAS_COLUMN,
      data: eraData?.getEra,
      isLoading: isEraLoading,
      count: eraData?.getEra?.count,
      PageController: <EraPageController />,
      pageSize: EraPageSize,
    },
    rewards: {
      columns: REWARDS_COLUMN,
      data: rewardData?.getStakingReward,
      isLoading: isRewardLoading,
      count: rewardData?.getStakingReward?.count,
      PageController: <RewardPageController />,
      pageSize: RewardPageSize,
    },
  };

  return { selectedTab, setSelectedTab, tableData, tabs };
};
