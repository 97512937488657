import { ITooltip, Tooltip as ReactTooltip } from 'react-tooltip';
import cn from '../../utils/classnames';

interface Tooltip extends ITooltip {
  id: string;
  content?: string;
  afterHide?: () => void;
  rest?: any;
}

export const Tooltip = ({
  id,
  content = '',
  className,
  afterHide = () => {},
  ...rest
}: Tooltip) => {
  return (
    <>
      <ReactTooltip
        id={id}
        opacity={1}
        content={content}
        className={cn('!bg-white !text-[#06030D] !text-sm !rounded-3xl !py-[3px] !px-2', className)}
        afterHide={afterHide}
        {...rest}
      />
    </>
  );
};
