import { Text } from 'components';
import TableColumns from 'components/Table/TableColumns';
import { FontColors } from 'consts';
import { Link } from 'react-router-dom';
import { routes } from 'routes/routesConst';
import { ColumnData } from 'types';

const LogsColumns = () => {
  return [
    {
      title: 'Log Index',
      key: 'index',
      shouldWrap: false,
      render: (data: ColumnData) => (
        <TableColumns classNames={{ content: 'text-white' }} value={data[data.columnKey]} />
      ),
    },
    {
      title: 'Block',
      key: 'block_number',
      render: (data: ColumnData) => (
        <Link
          to={`${data[data.columnKey] ? `${routes.block}/${data[data.columnKey] || '-'}` : ''}`}
        >
          <TableColumns
            value={data[data.columnKey] || '-'}
            textColor={FontColors.PURPLE}
            classNames={{ content: 'cursor-pointer' }}
          />
        </Link>
      ),
    },
    {
      title: 'Type',
      key: 'type',
      render: (data: ColumnData) => (
        <Text extraClass="text-white capitalize">{data[data.columnKey] || '-'}</Text>
      ),
    },
    {
      title: 'Engine',
      key: 'engine',
      render: (data: ColumnData) => (
        <Text extraClass="text-white capitalize">{data[data.columnKey] || '-'}</Text>
      ),
    },
  ];
};

export default LogsColumns;
