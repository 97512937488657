import { DetailsList } from '@analog/ui';
import { ResourceNotFound, SearchBox } from 'components';

import ExtrinsicEventList from './components/ExtrinsicEventList';
import useExtrinsicDetails from './useExtrinsicDetails';

const ExtrinsicDetails = () => {
  const { isLoading, overviewDetails, error, key, extrinsicId } = useExtrinsicDetails();

  if (error) {
    return <ResourceNotFound page={'extrinsics'} searchType={'Extrinsics'} />;
  }

  return (
    <>
      <div className="flex flex-col gap-4 md:gap-8">
        <SearchBox defaultSearchType={'All'} />
      </div>
      <div className="flex flex-col mt-8 md:mt-[60px] gap-8 lg:gap-10" key={key}>
        <DetailsList
          title={'Extrinsic Info'}
          details={overviewDetails}
          isLoading={isLoading}
          className="bg-[#060606]"
        />
        <ExtrinsicEventList extrinsicId={extrinsicId} />
      </div>
    </>
  );
};

export default ExtrinsicDetails;
