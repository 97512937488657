import { Tooltip } from '@analog/ui/src';
import { Card, SearchBox, Table } from 'components';
import { Skeleton } from 'components/Skeleton';
import { SortColumnsTask, arrayOfSize, classes } from 'utils';

import useGMPList from './useGMPList';

const GMPList = () => {
  const {
    tasksLoading,
    sortOrder,
    onSort,
    taskColumns,
    tasksData,
    pageSize,
    PageController,
    FilterController,
  } = useGMPList();
  return (
    <>
      <div className="flex flex-col gap-4 md:gap-8">
        <div className="flex gap-2">
          <SearchBox defaultSearchType={'All'} />
          {FilterController}
        </div>
      </div>
      <div className="mt-8 md:mt-[60px]">
        <Card extraClasses="flex gap-4 md:gap-[22px] flex-col">
          <span className="text-white md:text-xl text-lg font-normal leading-6 capitalize">
            GMP Messages
          </span>
          <Table
            columns={taskColumns}
            hasHeader
            data={!tasksLoading ? tasksData?.listTasks?.data : arrayOfSize(pageSize)}
            classNames={{
              row: classes(
                'last:border-none md:[&_>:nth-last-child(3)]:col-span-1 [&_>:nth-last-child(3)]:col-span-2 grid gap-x-4 grid-cols-2 md:grid-cols-[minmax(130px,10%)_minmax(140px,10%)_minmax(120px,10%)_minmax(150px,15%)_minmax(140px,20%)_minmax(120px,20%)_minmax(114px,10%)]'
              ),
              bodyRow: '!py-4 items-start !h-auto',
              bodyWrapper: 'min-h-[195px] border-b border-lightGray',
              columns: 'justify-start',
            }}
            sortOptions={SortColumnsTask}
            sortOrder={sortOrder}
            onSort={onSort}
            isLoading={tasksLoading}
            alwaysShowHeader
          />
          <Skeleton.Provider isLoading={tasksLoading}>
            {(tasksLoading || tasksData?.listTasks?.data?.length) && <PageController />}
          </Skeleton.Provider>
        </Card>
      </div>
      <Tooltip id={'signature'} />
    </>
  );
};

export default GMPList;
