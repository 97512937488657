import { useState } from 'react';

import { DEFAULT_PAGE_SIZE } from 'consts';
import { useApiAccess } from 'contexts/AccessProvider';
import { useGetValidatorsQuery } from 'gql';
import { usePagination } from 'hooks';

import useNominatorDetails from '../useNominatorDetails';

import VotesColumn from './VotesColumns';

type TabType = 'votes';

export const useNominatorRelationData = () => {
  const { sessionKey } = useApiAccess();
  const [selectedTab, setSelectedTab] = useState<TabType>('votes');
  const tabs: TabType[] = ['votes'];
  const { nominator } = useNominatorDetails();

  const {
    PageController: VotePageController,
    setTotalDataCount: setVoteTotalDataCount,
    pageSize: VotePageSize,
    pageNumber: VotePageNumber,
  } = usePagination({
    defaultPageSize: DEFAULT_PAGE_SIZE,
  });

  const { data: voteData, loading: isVoteLoading } = useGetValidatorsQuery({
    variables: {
      nominator: nominator?.nominatorDetails?.address as string,
      sessionKey,
      page: { size: VotePageSize, number: VotePageNumber },
    },
    onCompleted(data) {
      data.getValidators && setVoteTotalDataCount(data.getValidators?.count);
    },
    skip: !nominator?.nominatorDetails?.address,
    fetchPolicy: 'cache-first',
  });

  // const {
  //   PageController: RewardPageController,
  //   setTotalDataCount: setRewardTotalDataCount,
  //   pageSize: RewardPageSize,
  //   pageNumber: RewardPageNumber,
  // } = usePagination({
  //   defaultPageSize: DEFAULT_PAGE_SIZE,
  // });

  // const { data: rewardData, loading: isRewardLoading } = useGetStakingRewardQuery({
  //   variables: {
  //     sessionKey,
  //     validator: nominator?.nominatorDetails?.address,
  //     page: { size: RewardPageSize, number: RewardPageNumber },
  //   },
  //   onCompleted(data) {
  //     data.getStakingReward && setRewardTotalDataCount(data.getStakingReward?.count);
  //   },
  //   skip: selectedTab !== 'rewards',
  //   fetchPolicy: 'cache-first',
  // });

  const VOTES_COLUMN = VotesColumn();
  // const REWARDS_COLUMN = RewardsColumns();

  const tableData = {
    votes: {
      columns: VOTES_COLUMN,
      data: voteData?.getValidators,
      isLoading: isVoteLoading,
      count: voteData?.getValidators?.count,
      PageController: <VotePageController />,
      pageSize: VotePageSize,
    },
    // rewards: {
    //   columns: REWARDS_COLUMN,
    //   data: rewardData?.getStakingReward,
    //   isLoading: isRewardLoading,
    //   count: rewardData?.getStakingReward?.count,
    //   PageController: <RewardPageController />,
    //   pageSize: RewardPageSize,
    // },
  };

  return { selectedTab, setSelectedTab, tableData, tabs };
};;;;
