import { Icon } from '@analog/ui/src';
import allImgPaths from 'assets/allImgPaths';
import { Card, Switches, Table } from 'components';
import { useWindowSize } from 'hooks';
import { Link } from 'react-router-dom';
import { routes } from 'routes/routesConst';
import { arrayOfSize } from 'utils';

import useLatestTransfer from './useLatestTransfer';

export const LatestTransfer = () => {
  const { transferListLoading, COLUMNS, transferList, selectedTab, setSelectedTab, tabs } =
    useLatestTransfer();
  const { isMobile } = useWindowSize();
  return (
    <Card>
      <div className="flex flex-col gap-5">
        <div className="flex justify-between">
          <span className="text-white text-sm md:text-xl flex items-center">
            {!isMobile && (
              <Icon
                icon={allImgPaths.transferIcon}
                iconClasses="rotate-90"
                extraClasses="bg-transparent h-auto min-w-8 flex-none justify-start"
              />
            )}
            Transfers
          </span>
          <div className="flex flex-row justify-between gap-2 sm:gap-4">
            <Switches tabs={tabs} selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
            <Link className="flex flex-row gap-[5px] items-center shrink-0" to={routes.transfers}>
              <span className="text-white whitespace-nowrap cursor-pointer md:text-base text-sm">
                View All
              </span>
              <img
                src={allImgPaths.rightArrowIcon}
                alt="right-arrow"
                className="h-[14px] w-[14px] md:h-4 md:w-4 mt-[2.85px]"
              />
            </Link>
          </div>
        </div>
        <div>
          <Table
            columns={COLUMNS}
            animation
            classNames={{
              row: '!py-4 flex justify-between items-start sm:items-center',
              bodyBase: 'h-[360px] overflow-hidden',
              bodyWrapper:
                'flex flex-col-reverse min-h-[360px] h-[360px] max-h-[360px] overflow-auto',
              transfer: 'w-fit',
            }}
            data={
              !transferListLoading
                ? [...(transferList?.transfersList?.data || [])].reverse()
                : arrayOfSize(10)
            }
            isHeaderHidden
            bodyId="extrinsics"
            isLoading={transferListLoading}
          />
        </div>
      </div>
    </Card>
  );
};
