import React, { useRef } from 'react';

import allImgPaths from 'assets/allImgPaths';
import { IconButton, Text } from 'components';
import Dropdown from 'components/common/DropDown';
import { Skeleton } from 'components/Skeleton';

interface PaginationPropsType {
  pageNumber: number;
  setPageNumber: React.Dispatch<React.SetStateAction<number>>;
  setPageSize: React.Dispatch<number>;
  totalCount: number;
  defaultSize: number;
  onDownload?: () => void;
}

const Pagination = ({
  pageNumber,
  setPageNumber,
  setPageSize,
  totalCount,
  defaultSize,
  onDownload,
}: PaginationPropsType) => {
  const options = useRef(['10', '15', '20']);

  const handleSelect = (selectedOption: string) => {
    setPageSize(Number(selectedOption));
  };

  return (
    <div className="flex justify-between flex-wrap items-start md:items-center gap-1">
      <div className="flex flex-col md:flex-row gap-2 md:gap-[10px] items-start md:items-center">
        <div className="flex gap-[10px] items-center">
          <Skeleton.Loader
            containerClassName="sm:w-[69px] w-[59px] sm:h-6 h-5"
            className="w-full h-full"
          >
            <Text extraClass="text-xs md:text-sm">
              Page {pageNumber} of {Math.ceil(totalCount / defaultSize)}
            </Text>
          </Skeleton.Loader>
          <div className="flex gap-2 items-center">
            <Skeleton.Loader className="h-full w-full" containerClassName="h-10 w-10 rounded-full">
              <IconButton
                icon={allImgPaths.leftCaretIcon}
                onClick={() => {
                  setPageNumber((prev) => prev - 1);
                }}
                disabled={pageNumber == 1 || totalCount == 0}
              />
            </Skeleton.Loader>
            <Skeleton.Loader
              className="h-full w-full"
              containerClassName="rounded-[25px] w-[42px] md:w-16 h-[36px] md:h-10"
            >
              <div className="flex items-center justify-center text-white rounded-[25px] w-[42px] md:w-16 h-[36px] md:h-10 border border-solid border-[#FFFFFF14] bg-[linear-gradient(90deg,_#ffffff0a_0%,_#ffffff00_100%)]">
                <span className="text-xs md:text-sm">{pageNumber}</span>
              </div>
            </Skeleton.Loader>
            <Skeleton.Loader className="h-full w-full" containerClassName="h-10 w-10 rounded-full">
              <IconButton
                icon={allImgPaths.rightCaretIcon}
                onClick={() => {
                  setPageNumber((prev) => prev + 1);
                }}
                disabled={pageNumber == Math.ceil(totalCount / defaultSize) || totalCount == 0}
              />
            </Skeleton.Loader>
          </div>
        </div>
        <div className=" text-white">
          <Skeleton.Loader
            containerClassName="md:w-[149px] md:h-6 w-[127px] h-5"
            className="w-full h-full"
          >
            <span className="text-xs md:text-sm">
              {defaultSize * (pageNumber - 1) + 1} -{' '}
              {defaultSize * pageNumber >= totalCount ? totalCount : defaultSize * pageNumber}{' '}
              results of {totalCount} shown
            </span>
          </Skeleton.Loader>
        </div>
      </div>
      <div className="flex gap-[15px] bg-[#] items-center">
        {onDownload && (
          <Skeleton.Loader className="h-full w-full self-center" containerClassName=" h-6 !flex">
            <button
              onClick={onDownload}
              className="flex gap-2 justify-center items-center rounded-3xl border border-solid border-[#FFFFFF14] bg-[linear-gradient(90deg,_#ffffff0a_0%,_#ffffff00_100%)] px-6 h-10"
            >
              <img src={allImgPaths.downloadIcon} alt="Download" />
              <span className="text-white text-xs md:text-sm">Download</span>
            </button>
          </Skeleton.Loader>
        )}
        <Skeleton.Loader
          className="h-full w-full self-center"
          containerClassName="w-[34px] h-6 !flex"
        >
          <Text extraClass="text-xs md:text-sm">Rows</Text>
        </Skeleton.Loader>
        <Skeleton.Loader className="h-full w-full" containerClassName="w-[57px] h-10">
          <Dropdown
            options={options.current}
            onSelect={handleSelect}
            defaultSelected={defaultSize.toString()}
            classNames={{
              base: 'relative',
              selector:
                'shadow-none border border-solid border-[#FFFFFF14] bg-[linear-gradient(90deg,_#ffffff0a_0%,_#ffffff00_100%)]',
              optionContainer: 'px-3 py-0',
              container: 'bottom-[42px]',
            }}
          />
        </Skeleton.Loader>
      </div>
    </div>
  );
};

export default Pagination;
