import { Icon, Tooltip } from '@analog/ui/src';
import allImgPaths from 'assets/allImgPaths';
import { Text } from 'components';
import { Skeleton } from 'components/Skeleton';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { routes } from 'routes/routesConst';
import { ColumnData } from 'types';
import { timeAgo } from 'utils';

const LatestBlockColumn = () => {
  const navigate = useNavigate();
  return [
    {
      title: 'Block',
      key: 'number',
      shouldWrap: false,

      render: (data: ColumnData) => {
        return (
          <div className="flex gap-[10px] col-span-1">
            <div className={'flex flex-col overflow-hidden'}>
              <Skeleton.Loader className="lg:w-[100px] w-[50px]" containerClassName="h-5">
                <p className="text-sm">
                  Block#{'  '}
                  <Text
                    extraClass="text-pink text-base cursor-pointer w-fit"
                    onClick={() => {
                      navigate(`${routes.block}/${data[data.columnKey]}`);
                    }}
                  >
                    {data[data.columnKey]}
                  </Text>
                </p>
              </Skeleton.Loader>
              <Skeleton.Loader className="lg:w-[100px] w-[50px]" containerClassName="h-5">
                <p className="text-sm flex gap-1 text-[#9D9D9D]">
                  Includes{'  '}
                  <div className="flex gap-1.5 items-center">
                    <Text
                      extraClass="text-pink text-sm cursor-pointer"
                      onClick={() => navigate(`${routes.block}/${data[data.columnKey]}`)}
                    >
                      {data.extrinsic_count ? data.extrinsic_count : data.extrinsicsCount}{' '}
                      Extrinsics
                    </Text>
                    {/* <span className="text-pink flex items-start">.</span> */}
                    <Text
                      extraClass="text-pink text-sm cursor-pointer"
                      onClick={() => navigate(`${routes.block}/${data[data.columnKey]}`)}
                    >
                      {data.event_count ? data.event_count : data.eventsCount} Events
                    </Text>
                  </div>
                </p>
              </Skeleton.Loader>
            </div>
          </div>
        );
      },
    },

    {
      title: 'Age',
      key: 'created_at',
      render: (data: ColumnData) => {
        return (
          <div className={'flex flex-col overflow-hidden'}>
            <Skeleton.Loader className="lg:w-[100px] w-[50px]" containerClassName="h-5">
              <div className="flex items-center justify-end">
                <Tooltip
                  id={data[data.columnKey]}
                  content={moment.utc(data[data.columnKey]).format('YYYY-MM-DD hh:mm:ss (UTC)')}
                  className="!break-words !whitespace-normal !rounded-md"
                  clickable
                />
                <Text
                  extraClass="text-sm text-offWhite text-nowrap"
                  ellipsis={false}
                  data-tooltip-id={data[data.columnKey]}
                >
                  {data[data.columnKey] && timeAgo(new Date(data[data.columnKey]))}
                </Text>
                <Icon icon={allImgPaths.greenTickCircleIcon} extraClasses="bg-transparent h-0" />
              </div>
            </Skeleton.Loader>
          </div>
        );
      },
    },
  ];
};

export default LatestBlockColumn;
