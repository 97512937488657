import { useCallback } from 'react';

import { DEFAULT_PAGE_SIZE } from 'consts';
import { useApiAccess } from 'contexts/AccessProvider';
import { FilterType, useTransfersListQuery } from 'gql';
import { useFilter, usePagination, useSortBy } from 'hooks';
import { downloadCSV, formatAmount } from 'utils';

import TransferColumn from './components/TransferColumn';

function useTransferList() {
  const { sessionKey } = useApiAccess();
  const { PageController, setTotalDataCount, pageSize, pageNumber, setPageNumber } = usePagination({
    defaultPageSize: DEFAULT_PAGE_SIZE,
  });

  const { sortField, sortOrder, onSort } = useSortBy();

  const { FilterController, selectedField } = useFilter(FilterType.Transfer, setPageNumber);

  const { data, loading: isLoading } = useTransfersListQuery({
    variables: {
      sessionKey,
      page: { size: pageSize, number: pageNumber },
      ...(sortField && {
        sort: { field: sortField, order: sortOrder },
      }),
      filter: selectedField,
    },
    onCompleted(data) {
      setTotalDataCount(data.transfersList!.count);
    },
    skip: selectedField.column ? !selectedField.value : false,
    fetchPolicy: 'cache-and-network',
  });

  const transferColumn = TransferColumn();

  const handleClick = useCallback(() => {
    const transferData = data?.transfersList?.data;
    if (!transferData || transferData.length === 0) {
      return;
    }

    const transferHeaders = ['Index', 'Block', 'Timestamp', 'From', 'To', 'Value', 'Result'];

    const transferRows = transferData.map((transfer) => [
      transfer?.extrinsicId,
      transfer?.blockNumber,
      transfer?.created_at,
      transfer?.from,
      transfer?.to,
      formatAmount(transfer?.amount, 4),
      transfer?.extrinsicResult,
    ]);

    const fileName = `Transfers ${transferData[0]?.extrinsicId} - ${
      transferData[transferData.length - 1]?.extrinsicId
    }`;

    downloadCSV(fileName, transferHeaders, transferRows);
  }, [data]);

  return {
    PageController,
    transferData: data?.transfersList?.data,
    isLoading,
    pageSize,
    sortOrder,
    onSort,
    transferColumn,
    FilterController,
    totalTransfers: data?.transfersList?.count,
    handleClick,
  };
}

export default useTransferList;
