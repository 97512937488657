import { useCallback } from 'react';

import { DEFAULT_PAGE_SIZE } from 'consts';
import { useApiAccess } from 'contexts/AccessProvider';
import { FilterType, useListShardsQuery } from 'gql';
import { useFilter, usePagination, useSortBy } from 'hooks';
import { useNavigate } from 'react-router-dom';
import { downloadCSV, parseData } from 'utils';

import ShardColumn from './components/ShardColumn';

function useShardList() {
  const { sessionKey } = useApiAccess();
  const navigate = useNavigate();

  const { PageController, setTotalDataCount, pageSize, pageNumber, setPageNumber } = usePagination({
    defaultPageSize: DEFAULT_PAGE_SIZE,
  });

  const { FilterController, selectedField } = useFilter(FilterType.Shards, setPageNumber);

  const { sortField, sortOrder, onSort } = useSortBy();

  const {
    data: shardData,
    loading: isLoading,
    previousData,
  } = useListShardsQuery({
    variables: {
      sessionKey,
      page: { size: pageSize, number: pageNumber },
      ...(sortField && {
        sort: { field: sortField, order: sortOrder },
      }),
      filter: selectedField,
    },
    skip: selectedField.column ? !selectedField.value : false,
    onCompleted(data) {
      setTotalDataCount(data.listShards.count);
    },
    fetchPolicy: 'cache-and-network',
  });

  const shardColumn = ShardColumn({ navigate });

  const handleDownload = useCallback(() => {
    const shards = shardData?.listShards.data;
    if (!shards || shards.length === 0) {
      return;
    }
    const shardsHeaders = ['Shard Id', 'Network', 'State', 'Commitment', 'Signature'];
    const shardsRows = shards.map((shard) => [
      shard?.id,
      parseData(shard?.network as string),
      shard?.state,
      parseData(shard?.commitment, ';'),
      parseData(shard?.sig as string, ';'),
    ]);

    const fileName = `Shards ${shards[0]?.id} - ${shards[shards.length - 1]?.id}`;

    downloadCSV(fileName, shardsHeaders, shardsRows);
  }, [shardData]);

  return {
    PageController,
    shardData: shardData ? shardData?.listShards.data : previousData?.listShards.data,
    isLoading,
    pageSize,
    sortOrder,
    onSort,
    shardColumn,
    FilterController,
    handleDownload,
  };
}

export default useShardList;
