import { TitleIconCard, TruncatedText } from 'components';
import { Skeleton } from 'components/Skeleton';
import { classNames, classes } from 'utils';

interface CardProps {
  title: string;
  data: {
    image: string;
    title: string;
    text: string;
    tooltipContent?: string;
  }[];
  classNames?: classNames<'row'>;
}

export function ShardDetailsCard({ title, data, classNames }: CardProps) {
  return (
    <div
      className={classes(
        'card md:pt-[18px] pt-[15px] md:px-[24px] px-[16px] md:mt-[40px] mt-[32px] md:!rounded-[20px] !rounded-[16px]',
        classNames?.base
      )}
    >
      <div className="text-white md:text-xl text-[18px] font-normal leading-7 md:mb-[18px] mb-[15px]">
        <Skeleton.Loader
          className="md:w-[91px] md:h-7 w-[82px] h-6"
          containerClassName="leading-[0px]"
        >
          {title}
        </Skeleton.Loader>
      </div>
      {data.map((shard, index) => (
        <div
          className={classes(
            'grid md:grid-cols-[1fr,2fr] grid-cols-[1fr,1fr] items-center py-4 border-t border-[#1F1F1F]',
            classNames?.row
          )}
          key={index}
        >
          <TitleIconCard
            icon={shard.image}
            title={shard.title}
            tooltipContent={shard.tooltipContent}
          />
          <div
            className={classes(
              'text-white text-sm font-normal leading-snug whitespace-pre-line break-all',
              shard.title == 'Shard ID' && 'text-fuchsia-500'
            )}
          >
            <Skeleton.Loader
              className="md:w-[247px] md:h-[22px] w-[148px] h-11 "
              containerClassName="leading-[0px]"
            >
              <TruncatedText
                originalText={shard.text}
                dataToolTipId={shard.text}
                dataToolTipContent={shard.text}
                toolTipPlace="top-start"
              />
            </Skeleton.Loader>
          </div>
        </div>
      ))}
    </div>
  );
}
