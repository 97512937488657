import { Badge, DetailsListNavigation, HashIcon, Icon } from '@analog/ui/src';
import { StatusType } from '@analog/ui/src/components/Badge/Badge';
import allImgPaths from 'assets/allImgPaths';
import { TruncatedText } from 'components';
import { FontColors } from 'consts';
import { BlockDetailsQuery } from 'gql';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { routes } from 'routes/routesConst';
import { timeAgo } from 'utils';

interface BlockOverViewDetailsProps {
  blockDetailsData: BlockDetailsQuery | undefined;
}

const BlockOverViewDetails = ({ blockDetailsData }: BlockOverViewDetailsProps) => {
  const navigate = useNavigate();
  return [
    {
      icon: <Icon icon={allImgPaths.hashTagOutlineIcon} />,
      label: 'Block Number',
      value: (
        <div className="flex items-center gap-2">
          {blockDetailsData?.blockDetails?.number}
          <DetailsListNavigation
            handlePrevDetails={() =>
              blockDetailsData?.blockDetails?.previousBlock &&
              navigate(`${routes.block}/${blockDetailsData?.blockDetails?.previousBlock}`)
            }
            disablePrev={!blockDetailsData?.blockDetails?.previousBlock}
            handleNextDetails={() =>
              blockDetailsData?.blockDetails?.nextBlock &&
              navigate(`${routes.block}/${blockDetailsData?.blockDetails?.nextBlock}`)
            }
            disableNext={!blockDetailsData?.blockDetails?.nextBlock}
          />
        </div>
      ),
    },
    {
      icon: <Icon icon={allImgPaths.timerIcon} />,
      label: 'Timestamp',
      value: blockDetailsData?.blockDetails?.created_at
        ? `${timeAgo(new Date(blockDetailsData?.blockDetails?.created_at))} (${moment
            .utc(blockDetailsData?.blockDetails?.created_at)
            .format('MMM-D-YYYY h:mm:ss A +UTC')})`
        : '-',
    },
    {
      icon: <Icon icon={allImgPaths.tickCircleIcon} />,
      label: 'Status',
      value: <Badge status={blockDetailsData?.blockDetails?.status as StatusType} />,
    },
    {
      icon: <Icon icon={allImgPaths.hashTagIcon} />,
      label: 'Hash',
      value: (
        <TruncatedText
          originalText={blockDetailsData?.blockDetails?.hash as string}
          withCopyButton
          dataToolTipId={blockDetailsData?.blockDetails?.hash}
          dataToolTipContent={blockDetailsData?.blockDetails?.hash}
        />
      ),
    },
    {
      icon: <Icon icon={allImgPaths.hashTagDotIcon} />,
      label: 'Parent Hash',
      value: (
        <TruncatedText
          onClick={() => navigate(`${routes.block}/${blockDetailsData?.blockDetails?.parent_hash}`)}
          originalText={blockDetailsData?.blockDetails?.parent_hash as string}
          textColor={FontColors.PURPLE}
          className="cursor-pointer"
          withCopyButton
          dataToolTipId={blockDetailsData?.blockDetails?.parent_hash as string}
          dataToolTipContent={blockDetailsData?.blockDetails?.parent_hash as string}
          toolTipClass="cursor-text"
        />
      ),
    },
    {
      icon: <Icon icon={allImgPaths.stateRootIcon} />,
      label: 'State Root',
      value: (
        <TruncatedText
          originalText={blockDetailsData?.blockDetails?.state_root as string}
          withCopyButton
          dataToolTipId={blockDetailsData?.blockDetails?.state_root as string}
          dataToolTipContent={blockDetailsData?.blockDetails?.state_root as string}
        />
      ),
    },
    {
      icon: <Icon icon={allImgPaths.extrinsicsRootIcon} />,
      label: 'Extrinsic Root',
      value: (
        <TruncatedText
          originalText={blockDetailsData?.blockDetails?.extrinsic_root as string}
          withCopyButton
          dataToolTipId={blockDetailsData?.blockDetails?.extrinsic_root as string}
          dataToolTipContent={blockDetailsData?.blockDetails?.extrinsic_root as string}
        />
      ),
    },
    {
      icon: <Icon icon={allImgPaths.verifyIcon} />,
      label: 'Validator',
      value: (
        <HashIcon
          value={blockDetailsData?.blockDetails?.author}
          className="text-pink whitespace-pre-wrap cursor-pointer"
          size={20}
          copyable
          dataToolTipId={`${blockDetailsData?.blockDetails?.author}-validator` as string}
          dataToolTipContent={blockDetailsData?.blockDetails?.author as string}
          handleNavigate={() =>
            navigate(`${routes.validator}/${blockDetailsData?.blockDetails?.author}`)
          }
        />
      ),
    },
  ];
};

export default BlockOverViewDetails;
