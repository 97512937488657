import { useMemo } from 'react';

import { SearchBox } from 'components';

import { LatestBlocks } from './components/LatestBlocks/LatestBlocks';
import { LatestTransfer } from './components/LatestTransfers/LatestTransfer';
import { MembersTable } from './components/MembersTable/MembersTable';
import { TaskTable } from './components/TaskTable/TaskTable';
import Telemetry from './components/Telemetry/Telemetry';
import { useTelemetryData } from './components/Telemetry/useTelemetryData';

const HomePage = () => {
  const {
    supportedChain,
    telemetryData,
    supportedChainLoading,
    telemetryLoading,
    tokenDistributionData,
    tokenDistributionLoading,
  } = useTelemetryData();

  const LatestBlocksComponent = useMemo(() => <LatestBlocks />, []);
  const LatestTransferComponent = useMemo(() => <LatestTransfer />, []);
  const LatestTasksComponent = useMemo(() => <TaskTable />, []);
  return (
    <>
      <div className="flex flex-col gap-4 md:gap-[21px]">
        <SearchBox defaultSearchType={'All'} />
      </div>

      <div className="flex flex-col mt-[45px] gap-8 lg:gap-10">
        <Telemetry
          telemetryData={telemetryData}
          isTelemetryLoading={telemetryLoading}
          supportedChain={supportedChain?.supportedChains as string[]}
          isSupportedChainLoading={supportedChainLoading}
          tokenDistributionData={tokenDistributionData}
          tokenDistributionLoading={tokenDistributionLoading}
        />
        <div className="lg:grid grid-cols-1 md:grid-cols-2 flex flex-col gap-6 lg:gap-10">
          {LatestBlocksComponent}
          {LatestTransferComponent}
        </div>
        {LatestTasksComponent}
        <MembersTable />
      </div>
    </>
  );
};

export default HomePage;
