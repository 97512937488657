import { formatBalance } from '@polkadot/util';
import { AnalogIcon } from 'components';
import { Skeleton } from 'components/Skeleton';
import { Doughnut } from 'react-chartjs-2';
import { formatBalanceOption } from 'utils';

import { StakingChartStatType, useStakingChart } from './useStakingChart';
export interface StaingChartProp {
  telemetryData: StakingChartStatType;
}
export const StakingChart = ({ telemetryData }: StaingChartProp) => {
  const { data, options, nominatorStake, validatorStake, totalStake } = useStakingChart({
    telemetryData,
  });
  return (
    <div className="flex flex-col gap-4 2xl:w-[30%] sm:w-[40%] sm:mt-0 mt-5">
      <div className="sm:h-[-webkit-fill-available] flex items-center lg:justify-center justify-around gap-8 xl:gap-20 lg:gap-6 p-7 border border-[#2B2B2B] rounded-xl bg-[#010101] lg:flex-row sm:flex-col flex-col">
        <div className="relative md:w-[160px] md:h-[160px] w-[120px] h-[120px]">
          <Doughnut data={data} options={options} />
          <div className="absolute inset-0 flex justify-center items-center pointer-events-none">
            <AnalogIcon className="w-10 h-10 shrink-0 aspect-square mx-auto" />
          </div>
        </div>

        <div className="flex flex-col space-y-2 text-white">
          <div className="flex flex-col justify-between">
            <p className="text-white/70 text-xs font-medium">Total Staking</p>
            <Skeleton.Loader className="lg:w-[90px] w-[50px]" containerClassName="h-5">
              <p className="text-white text-lg font-medium">
                {formatBalance(totalStake, formatBalanceOption)} ANLOG
              </p>
            </Skeleton.Loader>
          </div>

          <div className="flex flex-col justify-between border-l-4 border-lightPink">
            <p className="text-sm text-white/70 pl-2 whitespace-nowrap">Validator Stake</p>
            <Skeleton.Loader className="lg:w-[90px] w-[50px]" containerClassName="h-5">
              <p className="text-white pl-2">
                {formatBalance(validatorStake, formatBalanceOption)} ANLOG
              </p>
            </Skeleton.Loader>
          </div>
          <div className="flex flex-col justify-between border-l-4 border-Purple">
            <p className="text-sm text-white/70 pl-2 whitespace-nowrap">Nominator Stake</p>
            <Skeleton.Loader className="lg:w-[90px] w-[50px]" containerClassName="h-5">
              <p className="text-white pl-2">
                {formatBalance(nominatorStake, formatBalanceOption)} ANLOG
              </p>
            </Skeleton.Loader>
          </div>
        </div>
      </div>
    </div>
  );
};
