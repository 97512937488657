import { useCallback } from 'react';

import { DEFAULT_PAGE_SIZE } from 'consts';
import { useApiAccess } from 'contexts/AccessProvider';
import { FilterType, useListExtrinsicsQuery } from 'gql';
import { useFilter, usePagination } from 'hooks';
import { downloadCSV } from 'utils';

import ExtrinsicsColumn from './components/ExtrinsicsColumn';

export function useExtrinsics() {
  const { sessionKey } = useApiAccess();
  const { PageController, setTotalDataCount, pageSize, pageNumber, setPageNumber } = usePagination({
    defaultPageSize: DEFAULT_PAGE_SIZE,
  });
  const { FilterController, selectedField } = useFilter(FilterType.Extrinsics, setPageNumber);

  const {
    data: extrinsicData,
    loading: isLoading,
    previousData,
  } = useListExtrinsicsQuery({
    variables: {
      sessionKey,
      page: { size: pageSize, number: pageNumber },
      filter: selectedField,
    },
    skip: selectedField.column ? !selectedField.value : false,
    onCompleted(data) {
      setTotalDataCount(data.listExtrinsics.count);
    },
    fetchPolicy: 'cache-and-network',
  });

  const handleDownload = useCallback(() => {
    const data = extrinsicData?.listExtrinsics.data;
    if (!data || data.length === 0) {
      return;
    }

    const extrinsicHeader = [
      'Extrinsic ID',
      'Block',
      'Extrinsic Hash',
      'Timestamp',
      'Result',
      'Action',
    ];

    const extrinsicRows = data.map((extrinsic) => [
      extrinsic?.id,
      extrinsic?.block_number,
      extrinsic?.hash,
      extrinsic?.created_at,
      extrinsic?.result,
      `${extrinsic?.section}(${extrinsic?.method})`,
    ]);

    const fileName = `Extrinsics ${data[0]?.id} - ${data[data.length - 1]?.id}`;

    downloadCSV(fileName, extrinsicHeader, extrinsicRows);
  }, [extrinsicData]);

  const COLUMNS = ExtrinsicsColumn();
  return {
    PageController,
    extrinsicsData: extrinsicData
      ? extrinsicData?.listExtrinsics.data
      : previousData?.listExtrinsics.data,
    isLoading,
    pageSize,
    pageNumber,
    COLUMNS,
    FilterController,
    totalExtrinsic: extrinsicData?.listExtrinsics.count,
    handleDownload,
  };
}
