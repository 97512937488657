import { formatBalance } from '@polkadot/util';
import { AnalogIcon } from 'components';
import { Skeleton } from 'components/Skeleton';
import TableColumns from 'components/Table/TableColumns';
import { FontColors } from 'consts';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { detailsRoutes, routes } from 'routes/routesConst';
import { ColumnData, ColumnsType } from 'types';
import { formatBalanceOptionRaw, newFormatBalance, timeAgo } from 'utils';

const RewardsColumns = (): ColumnsType[] => {
  const navigate = useNavigate();
  return [
    {
      title: 'Event ID',
      key: 'eventId',
      render: (data: ColumnData) => (
        <TableColumns
          value={data[data.columnKey]}
          classNames={{ content: 'cursor-pointer w-fit' }}
          textColor={FontColors.PURPLE}
          onClick={() => {
            data && navigate(`${detailsRoutes.eventDetails}/${data[data.columnKey]}`);
          }}
        />
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (data: ColumnData) => (
        <TableColumns
          value={`${data[data.columnKey]}`}
          classNames={{ content: 'text-white capitalize' }}
        />
      ),
    },
    {
      title: 'Reward Account',
      key: 'rewardAccount',
      render: (data: ColumnData) => (
        <TableColumns
          value={data[data.columnKey]}
          classNames={{ content: 'cursor-pointer w-fit' }}
          textColor={FontColors.PURPLE}
          withTruncate
          withCopyButton
          colIndex={`${data.created_at}-${data.columnKey}`}
          dataTooltipId={`${data.columnKey}-${data.created_at}`}
          dataTooltipContent={data[data.columnKey]}
          onClick={() => {
            data && navigate(`${routes.account}/${data[data.columnKey]}`);
          }}
        />
      ),
    },
    {
      title: 'Validator (Stash)',
      key: 'validatorStash',
      render: (data: ColumnData) => (
        <TableColumns
          value={data[data.columnKey]}
          classNames={{ content: 'cursor-pointer w-fit' }}
          textColor={FontColors.PURPLE}
          withTruncate
          withCopyButton
          colIndex={`${data.created_at}-${data.columnKey}`}
          dataTooltipId={`${data.columnKey}-${data.created_at}`}
          dataTooltipContent={data[data.columnKey]}
          onClick={() => {
            data && navigate(`${routes.account}/${data[data.columnKey]}`);
          }}
        />
      ),
    },
    {
      title: 'Era',
      key: 'era',
      render: (data: ColumnData) => (
        <TableColumns value={data[data.columnKey]} textColor={FontColors.WHITE} />
      ),
    },
    {
      title: 'Value',
      key: 'value',
      render: (data: ColumnData) => (
        <Skeleton.Loader className="w-20 h-4" containerClassName="flex items-center justify-center">
          <div className="flex flex-row items-center gap-1">
            <AnalogIcon className="min-w-4 w-4 min-h-4 h-4" />
            <TableColumns
              value={`${newFormatBalance(data[data.columnKey] || '0')} ANLOG`}
              classNames={{ content: 'text-white' }}
              dataTooltipId={data[data.columnKey]}
              dataTooltipContent={`${formatBalance(
                data[data.columnKey] || '0',
                formatBalanceOptionRaw
              )} ANLOG`}
              toolTipClass="!w-fit"
            />
          </div>
        </Skeleton.Loader>
      ),
    },
    {
      title: 'Age',
      key: 'created_at',
      render: (data: ColumnData) => (
        <TableColumns
          value={data[data.columnKey] ? timeAgo(new Date(data[data.columnKey])) : '-'}
          classNames={{ content: 'text-white', beforeIcon: 'h-4' }}
          dataTooltipId={data[data.columnKey]}
          dataTooltipContent={moment.utc(data[data.columnKey]).format('YYYY-MM-DD hh:mm:ss (UTC)')}
          toolTipClass="!w-fit"
        />
      ),
    },
  ];
};

export default RewardsColumns;
