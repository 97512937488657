import { CopyToClipboard, Tooltip } from '@analog/ui/src';
import { PlacesType } from 'react-tooltip';
import { classes } from 'utils';

import useTruncate from './useTruncate';

export interface TruncatedTextProps {
  originalText: string;
  width?: string;
  className?: string;
  withCopyButton?: boolean;
  colIndex?: string;
  textColor?: string;
  onClick?: () => void;
  dataToolTipId?: string;
  dataToolTipContent?: string;
  toolTipClass?: string;
  toolTipPlace?: PlacesType;
}
const TruncatedText = ({
  originalText,
  className,
  width = '100%',
  withCopyButton = false,
  colIndex,
  textColor,
  onClick,
  dataToolTipId,
  dataToolTipContent,
  toolTipClass,
  toolTipPlace,
  ...rest
}: TruncatedTextProps) => {
  const { truncatedText, containerRef } = useTruncate({ originalText });

  return (
    <div
      ref={containerRef}
      className={classes(
        'text-sm whitespace-wrap text-ellipsis',
        withCopyButton && 'flex gap-1 items-center',
        textColor,
        className
      )}
      style={{ width: width }}
    >
      <Tooltip
        id={dataToolTipId || ''}
        content={dataToolTipContent}
        clickable
        className={classes(
          '!w-full sm:!w-fit !break-words !whitespace-normal !rounded-md',
          toolTipClass
        )}
        place={toolTipPlace}
      />
      <div data-tooltip-id={dataToolTipId} onClick={onClick} {...rest}>
        {truncatedText}
      </div>
      {withCopyButton && originalText && originalText != '-' && (
        <CopyToClipboard icon content={originalText} id={originalText + colIndex} />
      )}
    </div>
  );
};

export default TruncatedText;
