import { HashIcon } from '@analog/ui/src';
import { formatBalance } from '@polkadot/util';
import { AnalogIcon, Text } from 'components';
import { useNavigate } from 'react-router-dom';
import { routes } from 'routes/routesConst';
import { formatBalanceOptionRaw, newFormatBalance } from 'utils';

type AssetsTransferProps = {
  transfers?: ({ from: string; to: string; amount: string; id: number } | null)[] | null;
};

export const AssetsTransfer = ({ transfers }: AssetsTransferProps) => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col gap-1 bg-[#111010] rounded-lg max-h-40">
      {transfers?.map((transfer, idx) => (
        <div key={transfer?.id} className="flex items-center gap-3 py-3 px-6 ">
          <div className="flex items-center min-w-10 text-gray-400 text-sm">#{idx + 1}</div>
          <div className="flex gap-4 flex-grow">
            <div className="flex items-center gap-1 sm:gap-2 min-w-[230px]">
              <span className="text-gray-400 text-sm">From</span>
              <HashIcon
                className="text-pink cursor-pointer"
                size={20}
                value={transfer?.from || '-'}
                widthTruncateManage
                copyable
                handleNavigate={() => {
                  navigate(`${routes.account}/${transfer?.from}`);
                }}
                dataToolTipContent={transfer?.from || ''}
                dataToolTipId={transfer?.from || ''}
              />
            </div>
            <div className="flex items-center gap-2 min-w-[215px]">
              <span className="text-gray-400 text-sm">To</span>
              <HashIcon
                className="text-pink cursor-pointer"
                copyable
                handleNavigate={() => {
                  navigate(`${routes.account}/${transfer?.to}`);
                }}
                size={20}
                widthTruncateManage
                value={transfer?.to || '-'}
                dataToolTipId={transfer?.to || ''}
                dataToolTipContent={transfer?.to || ''}
              />
            </div>
          </div>
          <div className="flex items-center gap-2 ml-auto">
            <span className="text-gray-400 text-sm whitespace-nowrap">For</span>
            <AnalogIcon className="w-5 h-5" />
            <Text
              extraClass="text-white text-sm font-medium whitespace-nowrap"
              dataToolTipId={transfer?.amount || ''}
              dataToolTipContent={`${formatBalance(
                transfer?.amount ? transfer?.amount.replaceAll(/[^0-9.]/g, '') : 0,
                formatBalanceOptionRaw
              )} ANLOG`}
              toolTipClass="!w-fit"
            >
              {newFormatBalance(
                transfer?.amount ? transfer?.amount.replaceAll(/[^0-9.]/g, '') : '0'
              )}{' '}
              ANLOG
            </Text>
          </div>
        </div>
      ))}
    </div>
  );
};
