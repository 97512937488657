import { Icon } from '@analog/ui/src';
import allImgPaths from 'assets/allImgPaths';
import Badge from 'components/common/Badge';
import { classes, DBtoStringValueMapping } from 'utils';

import { StatusStepperProps, useStatusStepper } from './useStatusStepper';

export const StatusStepper = ({ currentPhase }: StatusStepperProps) => {
  const { updatedStatus } = useStatusStepper({ currentPhase });

  return (
    <div className="flex flex-col gap-2 lg:flex-row items-start lg:items-center lg:justify-center xl:gap-4">
      {updatedStatus.map((status, index) => {
        return (
          <div key={status.label} className="flex items-start lg:items-center flex-col lg:flex-row">
            <div className="flex flex-row lg:flex-col items-center gap-[5px] mb-2 lg:mb-0">
              <Icon
                icon={status.isCompleted ? allImgPaths.greenTickIcon : status.icon}
                iconClasses={classes('w-6 h-6', status.isCompleted && 'max-w-6')}
                extraClasses={classes(
                  'max-w-8 p-3 rounded-full flex-none',
                  status.isCompleted ? 'bg-green/10' : 'bg-transparent border border-white/50'
                )}
              />
              {status.isCurrent ? (
                <Badge
                  label={
                    DBtoStringValueMapping[
                      updatedStatus[index].label as keyof typeof DBtoStringValueMapping
                    ]
                  }
                  badgeColor={'green'}
                />
              ) : (
                <span
                  className={classes(
                    'text-white text-xs xl:text-sm capitalize break-normal',
                    status.isFuture && 'text-white/50'
                  )}
                >
                  {
                    DBtoStringValueMapping[
                      updatedStatus[index].label as keyof typeof DBtoStringValueMapping
                    ]
                  }
                </span>
              )}
            </div>
            {index < updatedStatus.length - 1 && (
              <div
                className={classes(
                  'rotate-90 lg:rotate-0 w-4 lg:w-6 xl:w-12 h-[1px] mx-3 my-3  lg:my-0 lg:mb-5 lg:mx-[15.5px]',
                  status.isCompleted ? 'bg-green' : 'bg-white/50'
                )}
              />
            )}
          </div>
        );
      })}
    </div>
  );
};
