import { useCallback } from 'react';

import { DEFAULT_PAGE_SIZE } from 'consts';
import { useApiAccess } from 'contexts/AccessProvider';
import { FilterType, useListBlocksQuery } from 'gql';
import { useFilter, usePagination } from 'hooks';
import { downloadCSV } from 'utils';

import BlockColumn from './components/BlockColumns';

function useBlockList() {
  const { sessionKey } = useApiAccess();
  const { PageController, setTotalDataCount, pageSize, pageNumber, setPageNumber } = usePagination({
    defaultPageSize: DEFAULT_PAGE_SIZE,
  });

  const { FilterController, selectedField } = useFilter(FilterType.Block, setPageNumber);

  const {
    data: blockData,
    loading: isLoading,
    previousData,
  } = useListBlocksQuery({
    variables: {
      sessionKey,
      page: { size: pageSize, number: pageNumber },
      filter: selectedField,
    },
    skip: selectedField.column ? !selectedField.value : false,
    onCompleted(data) {
      setTotalDataCount(data.listBlocks.count);
    },
    fetchPolicy: 'cache-and-network',
  });

  const COLUMNS = BlockColumn();
  const handleDownload = useCallback(() => {
    if (!blockData?.listBlocks?.data || blockData.listBlocks.data.length === 0) {
      return;
    }

    const blockHeaders = [
      'Block',
      'Status',
      'Timestamp',
      'Extrinsics',
      'Events',
      'Validator',
      'Block Hash',
    ];
    const blockRows = blockData.listBlocks.data.map((block) => [
      block?.number,
      block?.status,
      block?.created_at,
      block?.extrinsic_count,
      block?.event_count,
      block?.author,
      block?.hash,
    ]);
    const fileName = `Blocks ${blockData.listBlocks.data[0]?.number} - ${
      blockData.listBlocks.data[blockData.listBlocks.data.length - 1]?.number
    }`;

    downloadCSV(fileName, blockHeaders, blockRows);
  }, [blockData]);

  return {
    COLUMNS,
    PageController,
    blockData: blockData ? blockData?.listBlocks.data : previousData?.listBlocks.data,
    isLoading,
    pageSize,
    FilterController,
    handleDownload,
  };
}

export default useBlockList;
