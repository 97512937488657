import { Dispatch, SetStateAction } from 'react';

interface SwitchesProps<T extends string> {
  tabs: T[];
  selectedTab: T;
  setSelectedTab: Dispatch<SetStateAction<T>>;
}

const Switches = <T extends string>({ tabs, selectedTab, setSelectedTab }: SwitchesProps<T>) => {
  return (
    <label className="shadow-card relative inline-flex cursor-pointer select-none items-center justify-center rounded-[25px] p-1 border-[1px] border-solid border-borderColor w-full md:w-auto">
      {tabs.map((tab) => (
        <span
          key={tab}
          className={`flex items-center space-x-[6px] rounded-[32px] py-[5px] px-4 text-sm font-medium text-white w-full md:w-auto justify-center capitalize ${
            selectedTab === tab ? 'text-primary bg-borderGray' : ''
          }`}
          onClick={() => setSelectedTab(tab)}
        >
          {tab}
        </span>
      ))}
    </label>
  );
};

export default Switches;
