import { DetailsList } from '@analog/ui';
import { ResourceNotFound, SearchBox } from 'components';

import ValidationRelationData from './components/ValidationRelationData';
import useValidatorDetails from './useValidatorDetails';

const ValidatorDetails = () => {
  const { isLoading, validatorData, error } = useValidatorDetails();

  if (error) {
    return <ResourceNotFound page={'validators'} searchType={'Validators'} />;
  }

  return (
    <>
      <div className="flex flex-col gap-4 md:gap-8">
        <SearchBox defaultSearchType={'All'} />
      </div>
      <div className="flex flex-col mt-8 md:mt-[60px] gap-8 lg:gap-10">
        <DetailsList title={'Validator Info'} details={validatorData} isLoading={isLoading} />
        <ValidationRelationData />
      </div>
    </>
  );
};

export default ValidatorDetails;
