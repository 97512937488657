import { formatBalance } from '@polkadot/util';
import { Skeleton } from 'components/Skeleton';
import { formatBalanceOption } from 'utils';

import { StakingStatType, useStakingStats } from './useStakingStats';

export interface StaingStatsProp {
  telemetryData: StakingStatType;
}

export const StakingStats = ({ telemetryData }: StaingStatsProp) => {
  const { stakingStats } = useStakingStats();
  return (
    <div className="2xl:w-[70%] sm:w-[60%] flex flex-col gap-4">
      <div className="sm:h-[-webkit-fill-available] gap-4 2xl:justify-evenly justify-between flex flex-col md:flex-row flex-wrap bg-primary rounded-2xl p-4 sm:p-7 border border-borderGray">
        {stakingStats.map(({ icon, title, key, postKey }) => (
          <div className="flex flex-col items-center justify-center" key={key}>
            {icon}
            <p className="text-white/70 text-sm whitespace-nowrap mt-3">{title}</p>
            <Skeleton.Loader className="lg:w-[90px] w-[50px]" containerClassName="h-5">
              {telemetryData && (
                <div className="text-sm text-white font-medium">
                  {postKey
                    ? `${telemetryData[key]?.toString() || '-'} / ${
                        telemetryData[postKey]?.toString() || '-'
                      }`
                    : `${formatBalance(telemetryData[key]?.toString(), {
                        ...formatBalanceOption,
                      })} ANLOG` || '-'}
                </div>
              )}
            </Skeleton.Loader>
          </div>
        ))}
        <div className="flex flex-col justify-evenly w-full md:w-[300px] lg:w-full xl:w-[250px] gap-3">
          <div className="flex flex-col">
            <div className="flex justify-between mb-1 text-white">
              <p>Era #{telemetryData?.era || ''}</p>
              <Skeleton.Loader className="lg:w-[90px] w-[50px]" containerClassName="h-5">
                <p>
                  {telemetryData?.eraProgress?.current || '0'}/{telemetryData?.eraProgress?.total}
                </p>
              </Skeleton.Loader>
            </div>
            <div className="w-full h-2 bg-white/20 rounded-md">
              <div
                className="h-2 bg-pink rounded-md"
                style={{
                  width: `${telemetryData?.eraProgress?.percentage || '0'}%`,
                }}
              ></div>
            </div>
          </div>
          <div className="flex flex-col">
            <div className="flex justify-between mb-1 text-white">
              <p>Epoch {telemetryData?.epoch && `#${telemetryData?.epoch}`}</p>
              <Skeleton.Loader className="lg:w-[90px] w-[50px]" containerClassName="h-5">
                <p>
                  {telemetryData?.epochProgress?.current || '0'}/
                  {telemetryData?.epochProgress?.total}
                </p>
              </Skeleton.Loader>
            </div>
            <div className="w-full h-2 bg-white/20 rounded-md">
              <div
                className="h-2 bg-pink rounded-md"
                style={{
                  width: `${telemetryData?.epochProgress?.percentage || '0'}%`,
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
