import { Dispatch, SetStateAction, useEffect, useMemo } from 'react';

import allImgPaths from 'assets/allImgPaths';
import { Card, Table } from 'components';
import { DEFAULT_PAGE_SIZE } from 'consts';
import { Maybe } from 'gql';
import { usePagination, useWindowSize } from 'hooks';
import { taskColumn } from 'pages/GMP/GMPList/components/TaskColumns';
import { useNavigate } from 'react-router-dom';
import { routes } from 'routes/routesConst';
import { TaskType } from 'types';
import { SortColumnsTask, arrayOfSize, classNames, classes } from 'utils';

interface TasksTableProps {
  isLoading?: boolean;
  taskData: Maybe<TaskType | undefined>[] | undefined | null;
  sortOrder?: string;
  onSort?: (field?: string) => void;
  defaultPageSize?: number;
  hasPagination?: boolean;
  defaultPageController?: JSX.Element;
  classNames?: classNames<
    'title' | 'row' | 'columns' | 'tableBase' | 'bodyBase' | 'bodyRow' | 'bodyWrapper'
  >;
  animation?: boolean;
  onPhaseChange?: Dispatch<SetStateAction<string>>;
  alwaysShowHeader?: boolean;
}

export const TasksTable = ({
  taskData,
  isLoading,
  sortOrder,
  onSort,
  defaultPageSize = DEFAULT_PAGE_SIZE,
  hasPagination,
  defaultPageController,
  classNames,
  animation,
  alwaysShowHeader,
  onPhaseChange,
}: TasksTableProps) => {
  const navigate = useNavigate();
  const windowSize = useWindowSize();

  const { taskColumns } = useMemo(() => {
    const array = taskColumn({ navigate, setPhase: onPhaseChange });
    if (windowSize.width < 769 && windowSize.width !== 0) {
      const element = array.splice(6, 1)[0];
      array.splice(3, 0, element);
    }
    return {
      taskColumns: array,
    };
  }, [navigate, taskData]);

  const { PageController, setTotalDataCount, pageSize, pageNumber } = usePagination({
    defaultPageSize: DEFAULT_PAGE_SIZE,
  });
  const { width } = useWindowSize();

  useEffect(() => {
    if (taskData) setTotalDataCount(taskData?.length);
  }, [taskData]);

  const paginatedData = useMemo(() => {
    if (taskData?.length) {
      const tempArray = [...taskData];
      return tempArray.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
    }
    return [];
  }, [taskData, pageNumber, pageSize]);

  useEffect(() => {
    if (animation) {
      const x = document?.getElementById('tasks')?.scrollTop;
      const height = width < 768 ? 260 : 70;
      const scrollByHeight =
        x && x >= 0 - height && x != -height
          ? document?.getElementById('tasks')?.scrollHeight || 0
          : height;

      document?.getElementById('tasks')?.scrollBy({
        top: -scrollByHeight,
        behavior: 'smooth',
      });
    }
  }, [paginatedData, width]);

  return (
    <Card extraClasses={classes('rounded-[20px] !p-5 flex flex-col gap-[18px]', classNames?.base)}>
      <div className="flex justify-between">
        <span className={classes('text-white text-xl', classNames?.title)}>Tasks</span>
      </div>

      <Table
        alwaysShowHeader
        animation={animation}
        columns={taskColumns}
        hasHeader
        data={
          !isLoading
            ? defaultPageController || !hasPagination
              ? taskData
              : paginatedData
            : arrayOfSize(defaultPageSize)
        }
        classNames={{
          row: classes(
            'md:[&_>:nth-last-child(3)]:col-span-1 [&_>:nth-last-child(3)]:col-span-2 grid gap-x-4 grid-cols-2 md:grid-cols-[minmax(130px,10%)_minmax(140px,10%)_minmax(120px,10%)_minmax(150px,15%)_minmax(140px,20%)_minmax(120px,20%)_minmax(120px,10%)]',
            classNames?.row
          ),
          base: classes(classNames?.tableBase),
          columns: 'justify-start',
          bodyWrapper: classNames?.bodyWrapper,
          bodyRow: classNames?.bodyRow,
        }}
        sortOptions={onSort ? SortColumnsTask : undefined}
        sortOrder={sortOrder}
        onSort={onSort}
        bodyId="tasks"
        isLoading={isLoading}
      />

      {hasPagination
        ? defaultPageController || <PageController />
        : taskData?.length && (
            <div className="flex justify-center">
              <button
                className="flex flex-row gap-[5px] items-center"
                onClick={() => navigate(routes.gmp)}
              >
                <span className="text-white whitespace-nowrap cursor-pointer">View All</span>
                <img
                  src={allImgPaths.rightArrowIcon}
                  alt="right-arrow"
                  className="h-[14px] w-[14px] md:h-4 md:w-4 mt-[2.85px]"
                />
              </button>
            </div>
          )}
    </Card>
  );
};
