import { HashIcon, Icon } from '@analog/ui';
import { formatBalance } from '@polkadot/util';
import allImgPaths from 'assets/allImgPaths';
import { AnalogIcon, Text } from 'components';
import JsonTable from 'components/common/JsonTable';
import { FontColors } from 'consts';
import { ValidatorDetailsQuery } from 'gql';
import { useNavigate } from 'react-router-dom';
import { routes } from 'routes/routesConst';
import { formatBalanceOptionHighest, timeAgo } from 'utils';

interface ValidatorDataProps {
  validator: ValidatorDetailsQuery | undefined;
}

const ValidatorData = ({ validator }: ValidatorDataProps) => {
  const transformToNumber = (td: string) => Number(td).toFixed(2);
  const navigate = useNavigate();

  return [
    {
      icon: <Icon icon={allImgPaths.globalSearchIcon} />,
      label: 'Stash',
      value: (
        <HashIcon
          size={20}
          copyable
          value={validator?.validatorDetails?.address}
          className="text-pink cursor-pointer"
          dataToolTipId={`${validator?.validatorDetails?.address}-stash` as string}
          dataToolTipContent={validator?.validatorDetails?.address as string}
          handleNavigate={() =>
            navigate(`${routes.account}/${validator?.validatorDetails?.address}`)
          }
        />
      ),
    },
    {
      icon: <Icon icon={allImgPaths.globalSearchIcon} />,
      label: 'Controller',
      value: (
        <HashIcon
          size={20}
          copyable
          value={validator?.validatorDetails?.controller}
          className="text-pink cursor-pointer"
          dataToolTipId={`${validator?.validatorDetails?.controller}-controller` as string}
          dataToolTipContent={validator?.validatorDetails?.controller as string}
          handleNavigate={() =>
            navigate(`${routes.account}/${validator?.validatorDetails?.controller}`)
          }
        />
      ),
    },
    {
      icon: <Icon icon={allImgPaths.dollarIcon} />,
      label: 'Self Bonded',
      value: (
        <div className="flex flex-row items-center gap-1">
          <AnalogIcon className="w-5 h-5" />
          <Text
            extraClass="text-white text-sm"
            dataToolTipId={validator?.validatorDetails?.selfBonded?.toFixed()}
            dataToolTipContent={`${formatBalance(
              validator?.validatorDetails?.selfBonded as number,
              formatBalanceOptionHighest
            )} ANLOG`}
            toolTipClass="!w-fit"
          >
            {formatBalance(
              validator?.validatorDetails?.selfBonded as number,
              formatBalanceOptionHighest
            )}{' '}
            ANLOG
          </Text>
        </div>
      ),
    },
    {
      icon: <Icon icon={allImgPaths.tickCircleIcon} />,
      label: 'Status',
      value: (
        <div className="flex items-center">
          <Text color={FontColors.WHITE}>{validator?.validatorDetails?.status}</Text>
          {validator?.validatorDetails?.status === 'ACTIVE' && (
            <Icon icon={allImgPaths.greenTickCircleIcon} extraClasses="bg-transparent h-0" />
          )}
        </div>
      ),
    },
    {
      icon: <Icon icon={allImgPaths.dollarIcon} />,
      label: 'Total Bonded',
      value: (
        <div className="flex flex-row items-center gap-1">
          <AnalogIcon className="w-5 h-5" />
          <Text
            extraClass="text-white text-sm"
            dataToolTipId={validator?.validatorDetails?.totalBonded?.toFixed()}
            dataToolTipContent={`${formatBalance(
              validator?.validatorDetails?.totalBonded as number,
              formatBalanceOptionHighest
            )} ANLOG`}
            toolTipClass="!w-fit"
          >
            {formatBalance(
              validator?.validatorDetails?.totalBonded as number,
              formatBalanceOptionHighest
            )}{' '}
            ANLOG
          </Text>
        </div>
      ),
    },
    {
      icon: <Icon icon={allImgPaths.hashTagOutlineIcon} />,
      label: 'Nominator',
      value: <>{validator?.validatorDetails?.nominatorCount}</>,
    },
    {
      icon: <Icon icon={allImgPaths.addIcon} />,
      label: 'Commission',
      value: <>{validator?.validatorDetails?.commission}</>,
    },
    {
      icon: <Icon icon={allImgPaths.infoIcon} />,
      label: 'Session Key',
      collapsible: true,
      value: (
        <JsonTable
          index={validator?.validatorDetails?.address.toString() as string}
          jsonData={validator?.validatorDetails?.keys}
        />
      ),
    },
    {
      icon: <Icon icon={allImgPaths.clockIcon} />,
      label: 'Discovered',
      value: <>{timeAgo(validator?.validatorDetails?.discoveredAt)}</>,
    },
    {
      icon: <Icon icon={allImgPaths.clockIcon} />,
      label: 'Updated',
      value: <>{timeAgo(validator?.validatorDetails?.updated_at)}</>,
    },
    {
      icon: <Icon icon={allImgPaths.rewardIcon} />,
      label: 'Reward Points',
      value: (
        <span>
          {transformToNumber(validator?.validatorDetails?.eraRewardPoints?.toString() || '0')}
        </span>
      ),
    },
    {
      icon: <Icon icon={allImgPaths.rewardIcon} />,
      label: 'Total Rewards',
      value: (
        <div className="flex flex-row items-center gap-1">
          <AnalogIcon className="w-5 h-5" />
          <Text
            extraClass="text-white text-sm"
            dataToolTipId={validator?.validatorDetails?.totalRewardPoints?.toFixed()}
            dataToolTipContent={`${formatBalance(
              validator?.validatorDetails?.totalRewardPoints as number,
              formatBalanceOptionHighest
            )} ANLOG`}
            toolTipClass="!w-fit"
          >
            {formatBalance(
              validator?.validatorDetails?.totalRewardPoints as number,
              formatBalanceOptionHighest
            )}{' '}
            ANLOG
          </Text>
        </div>
      ),
    },
    {
      icon: <Icon icon={allImgPaths.hashTagOutlineIcon} />,
      label: 'Latest Blocks',
      value: (
        <Text
          color={FontColors.PURPLE}
          extraClass="cursor-pointer"
          onClick={() => {
            navigate(`${routes.block}/${validator?.validatorDetails?.latestBlock}`);
          }}
        >
          {validator?.validatorDetails?.latestBlock}
        </Text>
      ),
    },
    {
      icon: <Icon icon={allImgPaths.hashTagOutlineIcon} />,
      label: 'Total Blocks',
      value: <>{validator?.validatorDetails?.totalBlocks}</>,
    },

    {
      icon: <Icon icon={allImgPaths.timerIcon} />,
      label: 'Active Era',
      value: <>{validator?.validatorDetails?.activeEra}</>,
    },
    {
      icon: <Icon icon={allImgPaths.periodIcon} />,
      label: 'Current Session',
      value: <>{validator?.validatorDetails?.currentSession}</>,
    },
  ];
};
export default ValidatorData;
