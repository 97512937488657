import { Icon } from '@analog/ui/src';
import { formatBalance } from '@polkadot/util';
import allImgPaths from 'assets/allImgPaths';
import { AnalogIcon, Text } from 'components';
import { Skeleton } from 'components/Skeleton';
import TableColumns from 'components/Table/TableColumns';
import { FontColors } from 'consts';
import { Link, useNavigate } from 'react-router-dom';
import { routes } from 'routes/routesConst';
import { ColumnData } from 'types';
import { formatBalanceOptionHighest } from 'utils';

const transformToNumber = (td: string) => Number(td).toFixed(2);

const ValidatorColumn = () => {
  const navigate = useNavigate();
  return [
    {
      title: 'Validator',
      key: 'address',
      render(data: ColumnData) {
        return (
          <div className="flex gap-[10px] col-span-2 md:col-span-1 cursor-pointer items-center">
            <Icon icon={allImgPaths.verifyIcon} />
            <div className={'flex flex-col overflow-hidden w-full'}>
              <TableColumns
                value={data[data.columnKey]}
                withTruncate
                withCopyButton
                toolTipClass="!cursor-text"
                textColor={FontColors.PURPLE}
                dataTooltipId={data[data.columnKey]}
                dataTooltipContent={data[data.columnKey]}
                onClick={() =>
                  data[data.columnKey] && navigate(`${routes.validator}/${data[data.columnKey]}`)
                }
              />
            </div>
          </div>
        );
      },
    },
    {
      title: 'Self Bonded',
      key: 'selfBonded',
      render(data: ColumnData) {
        return (
          <Skeleton.Loader className="flex md:justify-end" containerClassName="flex">
            <div className="flex flex-row items-center gap-1">
              <AnalogIcon className="w-5 h-5" />
              <Text
                extraClass="text-white text-sm"
                dataToolTipId={data[data.columnKey]?.toFixed()}
                dataToolTipContent={`${formatBalance(
                  data[data.columnKey],
                  formatBalanceOptionHighest
                )} ANLOG`}
                toolTipClass="!w-fit"
              >
                {formatBalance(data[data.columnKey], formatBalanceOptionHighest)} ANLOG
              </Text>
            </div>
          </Skeleton.Loader>
        );
      },
    },
    {
      title: 'Total Bonded',
      key: 'totalBonded',
      render(data: ColumnData) {
        return (
          <Skeleton.Loader className="flex md:justify-end" containerClassName="flex">
            <div className="flex flex-row items-center gap-1">
              <AnalogIcon className="w-5 h-5" />
              <Text
                extraClass="text-white text-sm"
                dataToolTipId={data[data.columnKey]?.toFixed()}
                dataToolTipContent={`${formatBalance(
                  data[data.columnKey],
                  formatBalanceOptionHighest
                )} ANLOG`}
                toolTipClass="!w-fit"
              >
                {formatBalance(data[data.columnKey], formatBalanceOptionHighest)} ANLOG
              </Text>
            </div>
          </Skeleton.Loader>
        );
      },
    },
    {
      title: 'Nominators',
      key: 'nominatorCount',
      render: (data: ColumnData) => (
        <TableColumns
          colIndex="nominatorCount"
          value={data.nominatorCount ? data[data.columnKey] : data.nominatorCount}
          classNames={{ content: 'text-white cursor-text' }}
        />
      ),
    },
    {
      title: 'Commission',
      key: 'commission',
      render(data: ColumnData) {
        return <Text extraClass="text-white text-sm">{data[data.columnKey]}</Text>;
      },
    },
    {
      title: 'Reward Points',
      key: 'eraRewardPoints',
      render(data: ColumnData) {
        return (
          <Skeleton.Loader className="flex md:justify-end" containerClassName="flex">
            <Text extraClass="text-white text-sm flex">
              {transformToNumber(data[data.columnKey])}
            </Text>
          </Skeleton.Loader>
        );
      },
    },
    {
      title: 'Latest Mining',
      key: 'latestBlock',
      render(data: ColumnData) {
        return (
          <Link to={`${routes.block}/${data[data?.columnKey]}`} className="cursor-pointer">
            <Text color={FontColors.PURPLE} extraClass="text-sm">
              {data[data.columnKey]}
            </Text>
          </Link>
        );
      },
    },
  ];
};
export default ValidatorColumn;
