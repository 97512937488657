import { useApiAccess } from 'contexts/AccessProvider';
import { useExtrinsicDetailsQuery } from 'gql';
import { useLocation, useParams } from 'react-router-dom';

import OverviewDetails from './components/OverviewDetails';

const useExtrinsicDetails = () => {
  const { id: idOrHash } = useParams();
  const { sessionKey } = useApiAccess();
  const { pathname } = useLocation();
  const isEvent = pathname.includes('event');

  const extrinsic = idOrHash?.startsWith('0x')
    ? { extrinsicHash: idOrHash }
    : isEvent
    ? { eventId: idOrHash }
    : { extrinsicId: idOrHash };

  const {
    data: extrinsicDetailsData,
    loading: isLoading,
    error,
  } = useExtrinsicDetailsQuery({
    variables: { sessionKey, ...extrinsic },
  });

  const overviewDetails = OverviewDetails({ extrinsicDetailsData });

  return {
    isLoading,
    extrinsicDetailsData,
    overviewDetails,
    error,
    key: idOrHash,
    extrinsicId: extrinsicDetailsData?.extrinsicDetails?.id,
  };
};

export default useExtrinsicDetails;
