import { Icon } from '@analog/ui/src';
import allImgPaths from 'assets/allImgPaths';

type ProgressType = {
  current: number;
  percentage: number;
  total: number;
};

export type StakingStatType = {
  activeValidators: number;
  totalValidators: number;
  activePools: number;
  totalPools: number;
  minimumStake: number;
  inflation_rate: number;
  era: number;
  eraProgress: ProgressType;
  epoch: number;
  epochProgress: ProgressType;
};

export const useStakingStats = () => {
  const stakingStats: {
    icon?: JSX.Element;
    title: string;
    key: keyof StakingStatType;
    postKey?: keyof StakingStatType;
  }[] = [
    {
      icon: (
        <Icon
          icon={allImgPaths.verifyIcon}
          iconClasses="h-6 w-6"
          extraClasses="bg-transparent h-auto min-w-8 flex-none justify-start"
        />
      ),
      title: 'Validators',
      key: 'activeValidators',
      postKey: 'totalValidators',
    },
    {
      icon: (
        <Icon
          icon={allImgPaths.dollarIcon}
          iconClasses="h-6 w-6"
          extraClasses="bg-transparent h-auto min-w-8 flex-none justify-start"
        />
      ),
      title: 'Nomination Pools',
      key: 'activePools',
      postKey: 'totalPools',
    },
    // {
    //   icon: (
    //     <Icon
    //       icon={allImgPaths.stakeIcon}
    //       iconClasses="h-6 w-6"
    //       extraClasses="bg-transparent h-auto min-w-8 flex-none justify-start"
    //     />
    //   ),
    //   title: 'Minimum Stake',
    //   key: 'minimumStake',
    // },
  ];
  return { stakingStats };
};
