import TableColumns from 'components/Table/TableColumns';
import { FontColors } from 'consts';
import { ColumnData, ColumnsType } from 'types';

const ErasColumns = (): ColumnsType[] => {
  return [
    {
      title: 'Era',
      key: 'era',
      render: (data: ColumnData) => (
        <TableColumns value={data[data.columnKey]} textColor={FontColors.WHITE} />
      ),
    },
    // {
    //   title: 'Start Block',
    //   key: 'startBlock',
    //   render: (data: ColumnData) => (
    //     <TableColumns
    //       value={data[data.columnKey] || '-'}
    //       classNames={{ content: 'cursor-pointer w-fit' }}
    //       textColor={data[data.columnKey] && FontColors.PURPLE}
    //       onClick={() => {
    //         data[data.columnKey] && navigate(`${routes.block}/${data[data.columnKey]}`);
    //       }}
    //     />
    //   ),
    // },
    // {
    //   title: 'End Block',
    //   key: 'endBlock',
    //   render: (data: ColumnData) => (
    //     <TableColumns
    //       value={data[data.columnKey] || '-'}
    //       classNames={{ content: 'cursor-pointer w-fit' }}
    //       textColor={data[data.columnKey] && FontColors.PURPLE}
    //       onClick={() => {
    //         data[data.columnKey] && navigate(`${routes.block}/${data[data.columnKey]}`);
    //       }}
    //     />
    //   ),
    // },
    {
      title: 'Reward Point',
      key: 'rewardPoints',
      render: (data: ColumnData) => (
        <TableColumns value={`${data[data.columnKey]}`} classNames={{ content: 'text-white' }} />
      ),
    },
    {
      title: 'Blocks Produced',
      key: 'blocksProduced',
      render: (data: ColumnData) => (
        <TableColumns value={`${data[data.columnKey]}`} classNames={{ content: 'text-white' }} />
      ),
    },
  ];
};

export default ErasColumns;
