import { useCallback, useState } from 'react';

import { DEFAULT_PAGE_SIZE } from 'consts';
import { useListValidatorsQuery, useStakingTelemetryQuery } from 'gql';
import { usePagination, useSortBy } from 'hooks';
import { downloadCSV } from 'utils';

import { useApiAccess } from '../../../contexts/AccessProvider';

import ValidatorColumn from './components/ValidatorColumns';

type TabType = 'active' | 'others';
export function useValidatorList() {
  const [selectedTab, setSelectedTab] = useState<TabType>('active');
  const tabs: TabType[] = ['active', 'others'];
  const { sessionKey } = useApiAccess();
  const { PageController, setTotalDataCount, pageSize, pageNumber } = usePagination({
    defaultPageSize: DEFAULT_PAGE_SIZE,
  });

  const { sortField, sortOrder, onSort } = useSortBy();

  const { data: validatorsData, loading: isLoading } = useListValidatorsQuery({
    variables: {
      page: { number: pageNumber, size: pageSize },
      ...(sortField && { sort: { field: sortField, order: sortOrder } }),
      sessionKey,
      isActive: selectedTab === 'active',
    },
    onCompleted(data) {
      setTotalDataCount(data.listValidators?.count || 0);
    },
    fetchPolicy: 'cache-and-network',
  });

  const { data: stakingTelemetry } = useStakingTelemetryQuery({
    variables: { sessionKey: sessionKey },
  });

  const VALIDATOR_COLUMNS = ValidatorColumn();

  const handleDownload = useCallback(() => {
    const validators = validatorsData?.listValidators.data;
    if (!validators || validators.length === 0) {
      return;
    }

    const validatorsHeader = [
      'Validator',
      'Selft Bonded',
      'Total Bonded',
      'Nominators',
      'Commission',
      'Rewards Point',
      'Latest Mining',
    ];

    const validatorsRow = validators.map((validator) => [
      validator?.address,
      `${validator?.selfBonded} ANLOG`,
      `${validator?.totalBonded} ANLOG`,
      validator?.nominatorCount,
      validator?.commission,
      validator?.eraRewardPoints,
      validator?.latestBlock,
    ]);

    const fileName = `Validators ${validators[0]?.address} - ${
      validators[validators.length - 1]?.address
    }`;

    downloadCSV(fileName, validatorsHeader, validatorsRow);
  }, [validatorsData]);

  return {
    PageController,
    validatorsData: validatorsData?.listValidators?.data || [],
    isLoading,
    pageSize,
    sortOrder,
    onSort,
    selectedTab,
    setSelectedTab,
    stakingTelemetry,
    handleDownload,
    VALIDATOR_COLUMNS,
    tabs,
  };
}
