import { formatBalance } from '@polkadot/util';
import { TooltipItem } from 'chart.js';
import { formatBalanceOption } from 'utils';

import { TokenStatsType } from '../../useTelemetry';

enum TokenLabels {
  Circulating = 'circulatingSupply',
  Staking = 'totalStaked',
  Treasury = 'treasury',
  Vesting = 'vesting',
}

export const useTokenChart = (stats: TokenStatsType) => {
  const circulatingSupply = formatBalance(stats.circulatingSupply?.amount, formatBalanceOption);
  const staking = formatBalance(stats.totalStaked?.amount, formatBalanceOption);
  const treasury = formatBalance(stats.treasury?.amount, formatBalanceOption);
  const totalIssuance = formatBalance(stats.totalIssuance, formatBalanceOption);
  const vesting = formatBalance(stats?.vesting?.amount, formatBalanceOption);

  const data = {
    labels: ['Circulating', 'Staking', 'Treasury', 'Vesting'],
    datasets: [
      {
        data: [
          stats.circulatingSupply?.amount,
          stats.totalStaked?.amount,
          stats.treasury?.amount,
          stats.vesting?.amount,
        ], // Values in Millions
        backgroundColor: ['#F0BAFF', '#C763FF', '#6F4480', '#A877B3'],
        borderWidth: 0,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    cutout: '80%',
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (tooltipItem: TooltipItem<'doughnut'>) => {
            const label = data.labels[tooltipItem.dataIndex] as keyof typeof TokenLabels;
            const key = TokenLabels[label] as keyof TokenStatsType;
            const value = stats[key];
            const percentage =
              typeof value === 'object' && 'percentage' in value
                ? value.percentage.toFixed(2)
                : '0';
            return ` ${percentage}%`;
          },
        },
      },
    },
  };
  return { data, options, circulatingSupply, staking, treasury, totalIssuance, vesting };
};
