import allImgPaths from 'assets/allImgPaths';
import TableColumns from 'components/Table/TableColumns';
import { FontColors } from 'consts';
import { Link, useNavigate } from 'react-router-dom';
import { routes } from 'routes/routesConst';
import { ColumnData } from 'types';
import { getIcon, parseJson } from 'utils';

const ChronicleColumns = () => {
  const navigate = useNavigate();
  return [
    {
      title: 'Account ID',
      key: 'account_id',
      shouldWrap: false,
      render: (data: ColumnData) => (
        <TableColumns
          value={data[data.columnKey]}
          withTruncate
          withCopyButton
          textColor={FontColors.PURPLE}
          label="User"
          classNames={{ content: 'cursor-pointer' }}
          dataTooltipId={data[data.columnKey]}
          dataTooltipContent={data[data.columnKey]}
          toolTipClass="cursor-text"
          onClick={() => {
            data[data.columnKey] && navigate(`${routes.chronicle}/${data[data.columnKey]}`);
          }}
        />
      ),
    },
    {
      title: 'Network',
      key: 'network',
      render: (data: ColumnData) => {
        const parsedData = parseJson(data[data.columnKey]);

        return (
          <div className="flex gap-1 flex-wrap">
            {parsedData && parsedData.length ? (
              parsedData?.map((value: string, index: number) => (
                <TableColumns
                  key={index}
                  beforeImagePath={value && getIcon(value)}
                  value={value + (index < parsedData.length - 1 ? ',' : '')}
                  textColor={FontColors.WHITE}
                  classNames={{ container: '!w-fit', base: '!w-fit', content: 'capitalize' }}
                />
              ))
            ) : (
              <TableColumns value="-" />
            )}
          </div>
        );
      },
    },
    {
      title: 'Signature',
      key: 'sig',
      tooltipContent:
        'A cryptographic output that each active Chronicle Node in a shard generates whenever it is called upon to sign off on a cross-chain transaction.',
      render: (data: ColumnData) => (
        <TableColumns
          value={data[data.columnKey]}
          withTruncate
          withCopyButton
          colIndex={data.colIndex}
          dataTooltipContent={data[data.columnKey]}
          dataTooltipId={data[data.columnKey]}
          classNames={{
            content: ' text-white',
          }}
          copyValue={data[data.columnKey]}
        />
      ),
    },
    {
      title: 'State',
      key: 'state',
      render: (data: ColumnData) => (
        <TableColumns
          value={data[data.columnKey]}
          beforeImagePath={data[data.columnKey] ? getIcon(data[data.columnKey]) : '-'}
          classNames={{
            beforeIcon: 'h-[9px] w-[9px]',
            content: 'capitalize text-white',
            skeletonText: 'min-w-5 max-w-10',
          }}
        />
      ),
    },
    {
      title: 'Shard ID',
      key: 'shardId',
      render: (data: ColumnData) =>
        data[data.columnKey] ? (
          <Link className="cursor-pointer w-fit" to={`${routes.shard}/${data[data.columnKey]}`}>
            <TableColumns
              value={data[data.columnKey]}
              textColor={FontColors.PURPLE}
              classNames={{ container: '!w-fit', base: '!w-fit' }}
            />
          </Link>
        ) : (
          <TableColumns value="-" />
        ),
    },
    {
      title: 'Heartbeat',
      key: 'heartbeat',
      tooltipContent:
        'A special transaction that validators //(i.e., Chronicle Nodes and Time Nodes) broadcast to the Timechain, proving that they are connected and active.',
      render: (data: ColumnData) => (
        <TableColumns
          value={data[data.columnKey] || '-'}
          beforeImagePath={data[data.columnKey] ? allImgPaths.heartIcon : '-'}
          classNames={{
            base: 'md:justify-end',
            beforeIcon: 'h-[10px] min-w-3 w-3',
            content: 'md:ml-auto capitalize text-white',
            container: '!w-fit',
          }}
        />
      ),
    },
  ];
};
export default ChronicleColumns;
