import { DetailsList } from '@analog/ui';
import { ResourceNotFound, SearchBox } from 'components';
import { Skeleton } from 'components/Skeleton';

import useGMPDetails from './useGMPDetails';

const GMPDetails = () => {
  const { isLoading, taskData, title, error, seeMore, setSeeMore } = useGMPDetails();

  if (error) {
    return <ResourceNotFound page={'gmp'} searchType={'Tasks'} />;
  }

  return (
    <Skeleton.Provider isLoading={isLoading}>
      <div className="flex flex-col gap-4 md:gap-8">
        <SearchBox defaultSearchType={'All'} />
      </div>
      <div className="md:mt-[40px] mt-[32px]">
        <DetailsList
          title={title}
          details={taskData}
          isLoading={isLoading}
          seeAll={setSeeMore}
          isSeeAll={!seeMore}
        />
      </div>
    </Skeleton.Provider>
  );
};

export default GMPDetails;
