import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js';
import { AnalogIcon } from 'components';
import { Doughnut } from 'react-chartjs-2';

import { TokenStatsType } from '../../useTelemetry';

import { useTokenChart } from './useTokenChart';

ChartJS.register(ArcElement, Tooltip, Legend);

export const DonutChart = (stats: TokenStatsType) => {
  const { data, options, circulatingSupply, staking, totalIssuance, vesting } =
    useTokenChart(stats);

  return (
    <div className="flex flex-col gap-4 2xl:w-[30%] sm:w-[40%] sm:mt-0 mt-5">
      <span className="md:text-2xl sm:text-xl text-base text-white">Token Distribution</span>
      <div className="sm:h-[-webkit-fill-available] border border-[#2B2B2B] rounded-xl bg-[#010101] shadow-md">
        <div className="flex gap-2 py-2 px-5 bg-white/10 rounded-t-xl">
          <p className="text-white 2xl:text-base text-sm font-medium">Total Issuance:</p>
          <p className="text-white 2xl:text-base text-sm font-medium">{totalIssuance} ANLOG</p>
        </div>
        <div className="flex items-center sm:justify-between justify-center sm:gap-4 gap-10 xl:gap-16 lg:gap-5 p-4 xl:mt-0 lg:mt-4 sm:mt-7">
          <div className="relative w-[120px] xl:w-[190px] lg:w-40 h-[120px] xl:h-[190px] lg:h-40 xl:pl-7">
            <Doughnut data={data} options={options} />
            <div className="absolute inset-0 flex justify-center items-center xl:pl-7 pointer-events-none">
              <AnalogIcon className="w-10 h-10 shrink-0 aspect-square mx-auto" />
            </div>
          </div>

          <div className="space-y-3 text-white sm:pr-[119px]">
            <ul className="sm:space-y-5 space-y-3">
              <li className="flex items-center text-sm sm:whitespace-nowrap">
                <span className="w-2 h-8 bg-lightPink rounded-[2px] mr-2"></span>
                <div className="flex flex-col text-white/70 xl:text-sm text-xs">
                  Circulating{' '}
                  <span className="text-white xl:text-sm text-xs">{circulatingSupply} ANLOG</span>
                </div>
              </li>
              <li className="flex items-center text-sm sm:whitespace-nowrap">
                <span className="w-2 h-8 bg-[#A877B3] rounded-[2px] mr-2"></span>
                <div className="flex flex-col text-white/70 xl:text-sm text-xs">
                  Vesting <span className="text-white xl:text-sm text-xs">{vesting} ANLOG</span>
                </div>
              </li>
              <li className="flex items-center text-sm sm:whitespace-nowrap">
                <span className="w-2 h-8 bg-Purple rounded-[2px] mr-2"></span>
                <div className="flex flex-col text-white/70 xl:text-sm text-xs">
                  Staking <span className="text-white xl:text-sm text-xs">{staking} ANLOG</span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
