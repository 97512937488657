import { useApiAccess } from 'contexts/AccessProvider';
import {
  TelemetryDocument,
  useNewTelemetrySubscription,
  useSupportedChainsQuery,
  useTelemetryQuery,
  useTokenDistributionQuery,
} from 'gql';

export const useTelemetryData = () => {
  const { sessionKey } = useApiAccess();

  const { data: telemetryData, loading: telemetryLoading } = useTelemetryQuery({
    variables: { sessionKey },
    fetchPolicy: 'cache-and-network',
  });

  useNewTelemetrySubscription({
    onData(options) {
      const {
        finalized_blocks,
        transfers,
        shards_live,
        active_members,
        tasks_created,
        extrinsics,
        total_accounts,
      } = {
        ...options.data.data?.newTelemetryData,
      };

      options.client.writeQuery({
        query: TelemetryDocument,
        data: {
          ...telemetryData,
          telemetry: {
            ...telemetryData?.telemetry,
            ...(finalized_blocks && { finalized_blocks }),
            ...(transfers && { transfers }),
            ...(shards_live && { shards_live }),
            ...(active_members && { active_members }),
            ...(tasks_created && { tasks_created }),
            ...(extrinsics && { extrinsics }),
            ...(total_accounts && { total_accounts }),
          },
        },
        variables: {
          sessionKey,
        },
        broadcast: true,
      });
    },
  });

  const { data: tokenDistributionData, loading: tokenDistributionLoading } =
    useTokenDistributionQuery({
      variables: {
        sessionKey,
      },
    });

  const { data: supportedChain, loading: supportedChainLoading } = useSupportedChainsQuery({
    variables: { sessionKey },
    fetchPolicy: 'cache-and-network',
  });

  return {
    supportedChain,
    supportedChainLoading,
    telemetryData,
    telemetryLoading,
    tokenDistributionData,
    tokenDistributionLoading,
  };
};
