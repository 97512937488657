import { Card, Switches, Table } from 'components';
import { Skeleton } from 'components/Skeleton';
import { arrayOfSize } from 'utils';

import { useValidationRelationData } from './useValidationRelationData';

const ValidationRelationData = () => {
  const { selectedTab, setSelectedTab, tableData, tabs } = useValidationRelationData();
  const rowClass = {
    nominators:
      'grid gap-x-2 grid-cols-2 md:grid-cols-[minmax(200px,60%)_minmax(150px,20%)_minmax(150px,20%)] border-0',
    eras: 'grid gap-x-2 grid-cols-2 md:grid-cols-[minmax(100px,33%)_minmax(100px,33%)_minmax(100px,33%)] border-0',
    rewards:
      'grid gap-x-2 grid-cols-2 md:grid-cols-[minmax(120px,10%)_minmax(170px,15%)_minmax(150px,20%)_minmax(170px,25%)_minmax(70px,5%)_minmax(150px,15%)_minmax(100px,10%)] border-0',
  };

  return (
    <Skeleton.Provider isLoading={tableData[selectedTab].isLoading}>
      <Card extraClasses="flex gap-4 md:gap-[22px] flex-col">
        <div className="flex justify-between items-center flex-col-reverse md:flex-row gap-[15px]">
          <Skeleton.Loader className="w-[100px] h-5" containerClassName="h-5">
            <span className="text-white text-xl">
              <div className="capitalize">
                {selectedTab}
                <span className="text-offWhite text-base">
                  {' '}
                  ({tableData[selectedTab].count || 0})
                </span>
              </div>
            </span>
          </Skeleton.Loader>
          <Skeleton.Loader className="md:w-[200px] w-full h-10" containerClassName="h-10">
            <Switches tabs={tabs} selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
          </Skeleton.Loader>
        </div>
        <Table
          columns={tableData[selectedTab].columns}
          hasHeader
          data={
            !tableData[selectedTab].isLoading ? tableData[selectedTab].data?.data : arrayOfSize(2)
          }
          classNames={{
            row: rowClass[selectedTab],
            columns: '!justify-start',
          }}
          isLoading={tableData[selectedTab].isLoading}
          key={selectedTab}
        />
        {tableData[selectedTab].PageController}
      </Card>
    </Skeleton.Provider>
  );
};

export default ValidationRelationData;
