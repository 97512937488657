import { Icon, NetworkDetails, Tooltip } from '@analog/ui';
import allImgPaths from 'assets/allImgPaths';
import { Text, TruncatedText } from 'components';
import Badge from 'components/common/Badge';
import JsonTable from 'components/common/JsonTable';
import { Skeleton } from 'components/Skeleton';
import { FontColors } from 'consts';
import moment from 'moment';
import { tooltipOfPhase } from 'pages/GMP/GMPList/components/TaskColumns';
import { routes } from 'routes/routesConst';
import { GMPTaskType } from 'types';
import { colors, colorType, DBtoStringValueMapping, getLinkFromNetwork, timeAgo } from 'utils';

import { StatusStepper } from './StatusStepper';
import { TaskDataProps, useTaskData } from './useTaskData';

const TaskData = ({ taskDetailsData }: TaskDataProps) => {
  const { navigate, taskPhase } = useTaskData({ taskDetailsData });
  return [
    {
      icon: <Icon icon={allImgPaths.taskIcon} />,
      label: 'Task ID',
      value: (
        <Skeleton.Loader>
          <div className="text-white">{taskDetailsData?.taskDetails.taskId}</div>
        </Skeleton.Loader>
      ),
      enable: !!taskDetailsData?.taskDetails.taskId,
    },
    {
      icon: <Icon icon={allImgPaths.taskIcon} />,
      label: 'GMP ID',
      value: (
        <Skeleton.Loader>
          <div className="text-white">{taskDetailsData?.taskDetails.gmpId}</div>
        </Skeleton.Loader>
      ),
      enable: !!taskDetailsData?.taskDetails.gmpId,
    },

    {
      icon: <Icon icon={allImgPaths.hashTagOutlineIcon} />,
      label: 'Shard ID',
      value: (
        <Skeleton.Loader>
          <div
            className="text-pink cursor-pointer"
            onClick={() => {
              taskDetailsData?.taskDetails.shardId &&
                navigate(`${routes.shard}/${taskDetailsData.taskDetails.shardId.toString()}`);
            }}
          >
            {taskDetailsData?.taskDetails.shardId}
          </div>
        </Skeleton.Loader>
      ),
      enable: !!taskDetailsData?.taskDetails.shardId || false,
    },

    {
      icon: <Icon icon={allImgPaths.phaseIcon} />,
      label: 'Phase',
      value: (
        <Skeleton.Loader>
          <StatusStepper currentPhase={taskPhase || '-'} />
        </Skeleton.Loader>
      ),
      enable: !!taskDetailsData?.taskDetails.phase || false,
    },

    {
      icon: <Icon icon={allImgPaths.verifyIcon} />,
      label: 'Verification',
      value: (
        <Skeleton.Loader>
          <Tooltip
            id={
              tooltipOfPhase[
                taskDetailsData?.taskDetails?.verification
                  ? (taskDetailsData?.taskDetails?.verification.toLowerCase() as keyof typeof tooltipOfPhase)
                  : (taskDetailsData?.taskDetails?.phase?.toLowerCase() as keyof typeof tooltipOfPhase)
              ]
            }
            content={
              tooltipOfPhase[
                taskDetailsData?.taskDetails?.verification
                  ? (taskDetailsData?.taskDetails?.verification.toLowerCase() as keyof typeof tooltipOfPhase)
                  : (taskDetailsData?.taskDetails?.phase?.toLowerCase() as keyof typeof tooltipOfPhase)
              ]
            }
            className="!w-fit !rounded-lg z-10"
            clickable
          />
          <Badge
            label={
              DBtoStringValueMapping[
                taskDetailsData?.taskDetails?.verification
                  ? (taskDetailsData?.taskDetails
                      ?.verification as keyof typeof DBtoStringValueMapping)
                  : (taskDetailsData?.taskDetails?.phase as keyof typeof DBtoStringValueMapping)
              ]
            }
            badgeColor={
              colors[
                taskDetailsData?.taskDetails?.verification
                  ? (taskDetailsData?.taskDetails?.verification as keyof typeof colors)
                  : (taskDetailsData?.taskDetails?.phase as keyof typeof colors)
              ] as colorType
            }
            data-tooltip-id={
              tooltipOfPhase[
                taskDetailsData?.taskDetails?.verification
                  ? (taskDetailsData?.taskDetails?.verification.toLowerCase() as keyof typeof tooltipOfPhase)
                  : (taskDetailsData?.taskDetails?.phase?.toLowerCase() as keyof typeof tooltipOfPhase)
              ]
            }
          />
        </Skeleton.Loader>
      ),
      enable: !!taskDetailsData?.taskDetails.verification,
    },

    {
      icon: <Icon icon={allImgPaths.cube3DIcon} />,
      label: 'Salt',
      value: (
        <TruncatedText
          originalText={taskDetailsData?.taskDetails.salt as string}
          textColor={FontColors.WHITE}
          withCopyButton
          dataToolTipId={taskDetailsData?.taskDetails.salt as string}
          dataToolTipContent={taskDetailsData?.taskDetails.salt as string}
        />
      ),
      enable: !!taskDetailsData?.taskDetails.salt,
    },
    {
      icon: <Icon icon={allImgPaths.sourceNetworkIcon} />,
      label: 'Source Network',
      value: taskDetailsData?.taskDetails.type === GMPTaskType.SendMessage && (
        <div className="flex gap-1 text-white">
          {taskDetailsData?.taskDetails.sourceNetwork ? (
            <Skeleton.Loader>
              <NetworkDetails data={taskDetailsData?.taskDetails.sourceNetwork} />
            </Skeleton.Loader>
          ) : (
            <Skeleton.Loader className="h-4 w-10">-</Skeleton.Loader>
          )}
        </div>
      ),
      enable: !!taskDetailsData?.taskDetails.sourceNetwork,
    },

    {
      icon: <Icon icon={allImgPaths.shardNetworkIcon} />,
      label: 'Destination Network',
      value: (
        <div className="flex gap-1 text-white flex-wrap">
          {taskDetailsData?.taskDetails.network ? (
            <Skeleton.Loader>
              <NetworkDetails data={taskDetailsData?.taskDetails.network} />
            </Skeleton.Loader>
          ) : (
            <Skeleton.Loader className="h-4 w-10">-</Skeleton.Loader>
          )}
        </div>
      ),
      enable: !!taskDetailsData?.taskDetails.network,
    },

    {
      icon: <Icon icon={allImgPaths.globalSearchIcon} />,
      label: 'Source Address',
      value: (
        <TruncatedText
          originalText={taskDetailsData?.taskDetails.source as string}
          textColor={FontColors.PURPLE}
          className="cursor-pointer"
          toolTipClass="!cursor-text"
          onClick={() => {
            const redirectLink = getLinkFromNetwork(
              `address/${taskDetailsData?.taskDetails.source}`,
              taskDetailsData?.taskDetails.sourceNetwork
            );
            redirectLink && window.open(redirectLink, '_blank');
          }}
          withCopyButton
          dataToolTipId={taskDetailsData?.taskDetails.source as string}
          dataToolTipContent={taskDetailsData?.taskDetails.source as string}
        />
      ),
      enable: !!taskDetailsData?.taskDetails.source,
    },

    {
      icon: <Icon icon={allImgPaths.globalSearchIcon} />,
      label: 'Destination Address',
      value: (
        <TruncatedText
          originalText={taskDetailsData?.taskDetails.destination as string}
          textColor={FontColors.PURPLE}
          className="cursor-pointer"
          toolTipClass="!cursor-text"
          onClick={() => {
            const redirectLink = getLinkFromNetwork(
              `address/${taskDetailsData?.taskDetails.destination}`,
              taskDetailsData?.taskDetails.network
            );
            redirectLink && window.open(redirectLink, '_blank');
          }}
          withCopyButton
          dataToolTipId={taskDetailsData?.taskDetails.destination as string}
          dataToolTipContent={taskDetailsData?.taskDetails.destination as string}
        />
      ),
      enable: !!taskDetailsData?.taskDetails.destination,
    },
    {
      icon: <Icon icon={allImgPaths.hashTagIcon} />,
      label: 'Source Transaction hash',
      value: (
        <TruncatedText
          originalText={taskDetailsData?.taskDetails.srcTxHash as string}
          textColor={FontColors.PURPLE}
          className="cursor-pointer"
          toolTipClass="!cursor-text"
          onClick={() => {
            const redirectLink = getLinkFromNetwork(
              `tx/${taskDetailsData?.taskDetails.srcTxHash}`,
              taskDetailsData?.taskDetails.sourceNetwork
            );
            redirectLink && window.open(redirectLink, '_blank');
          }}
          withCopyButton
          dataToolTipId={taskDetailsData?.taskDetails.srcTxHash as string}
          dataToolTipContent={taskDetailsData?.taskDetails.srcTxHash as string}
        />
      ),
      enable: !!taskDetailsData?.taskDetails.srcTxHash,
    },
    {
      icon: <Icon icon={allImgPaths.hashTagIcon} />,
      label: 'Destination Transaction Hash',
      value: (
        <TruncatedText
          originalText={taskDetailsData?.taskDetails.destTxHash as string}
          textColor={FontColors.PURPLE}
          className="cursor-pointer"
          toolTipClass="!cursor-text"
          onClick={() => {
            const redirectLink = getLinkFromNetwork(
              `tx/${taskDetailsData?.taskDetails.destTxHash}`,
              taskDetailsData?.taskDetails.network
            );
            redirectLink && window.open(redirectLink, '_blank');
          }}
          withCopyButton
          dataToolTipId={taskDetailsData?.taskDetails.destTxHash as string}
          dataToolTipContent={taskDetailsData?.taskDetails.destTxHash as string}
        />
      ),
      enable: !!taskDetailsData?.taskDetails.destTxHash,
    },
    {
      icon: <Icon icon={allImgPaths.unlinkIcon} />,
      label: 'Unassigned Reason',
      value: (
        <Skeleton.Loader>
          <div className="text-white">{taskDetailsData?.taskDetails.unAssignedReason || '-'}</div>
        </Skeleton.Loader>
      ),
      enable: !!taskDetailsData?.taskDetails.unAssignedReason,
    },
    {
      icon: <Icon icon={allImgPaths.clockIcon} />,
      label: 'Created At',
      value: taskDetailsData?.taskDetails?.created_at
        ? `${timeAgo(new Date(taskDetailsData?.taskDetails?.created_at))} (${moment
            .utc(taskDetailsData?.taskDetails?.created_at)
            .format('MMM-D-YYYY h:mm:ss A +UTC')})`
        : '-',
      enable: !!taskDetailsData?.taskDetails.created_at,
    },
    {
      icon: <Icon icon={allImgPaths.clockIcon} />,
      label: 'Updated At',
      value: taskDetailsData?.taskDetails?.updated_at
        ? `${timeAgo(new Date(taskDetailsData?.taskDetails?.updated_at))} (${moment
            .utc(taskDetailsData?.taskDetails?.updated_at)
            .format('MMM-D-YYYY h:mm:ss A +UTC')})`
        : '-',
      enable: !!taskDetailsData?.taskDetails.updated_at,
    },
    {
      icon: <Icon icon={allImgPaths.transferIcon} />,
      label: 'Proposed At Block',
      value: (
        <Text
          extraClass="cursor-pointer"
          color={FontColors.PURPLE}
          onClick={() =>
            taskDetailsData?.taskDetails?.proposed_at_block &&
            navigate(`${routes.block}/${taskDetailsData?.taskDetails?.proposed_at_block}`)
          }
        >
          {taskDetailsData?.taskDetails?.proposed_at_block}
        </Text>
      ),
      enable: !!taskDetailsData?.taskDetails.proposed_at_block,
    },
    {
      icon: <Icon icon={allImgPaths.clipboardTickIcon} />,
      label: 'Modified At Block',
      value: (
        <Text
          extraClass="cursor-pointer"
          color={FontColors.PURPLE}
          onClick={() =>
            taskDetailsData?.taskDetails?.modified_at_block &&
            navigate(`${routes.block}/${taskDetailsData?.taskDetails?.modified_at_block}`)
          }
        >
          {taskDetailsData?.taskDetails?.modified_at_block}
        </Text>
      ),
      enable: !!taskDetailsData?.taskDetails.modified_at_block,
    },
    {
      icon: <Icon icon={allImgPaths.clipboardTickIcon} />,
      label: 'Source Block',
      value: (
        <Text
          extraClass="cursor-pointer"
          color={FontColors.PURPLE}
          onClick={() => {
            const redirectLink = getLinkFromNetwork(
              `block/${taskDetailsData?.taskDetails.srcBlock}`,
              taskDetailsData?.taskDetails.sourceNetwork
            );
            redirectLink && window.open(redirectLink, '_blank');
          }}
        >
          {taskDetailsData?.taskDetails?.srcBlock}
        </Text>
      ),
      enable: !!taskDetailsData?.taskDetails.srcBlock,
    },
    {
      icon: <Icon icon={allImgPaths.clipboardTickIcon} />,
      label: 'Destination Block',
      value: (
        <Text
          extraClass="cursor-pointer"
          color={FontColors.PURPLE}
          onClick={() => {
            const redirectLink = getLinkFromNetwork(
              `block/${taskDetailsData?.taskDetails.destBlock}`,
              taskDetailsData?.taskDetails.network
            );
            redirectLink && window.open(redirectLink, '_blank');
          }}
        >
          {taskDetailsData?.taskDetails?.destBlock}
        </Text>
      ),
      enable: !!taskDetailsData?.taskDetails.destBlock,
    },
    {
      icon: <Icon icon={allImgPaths.shardSignature} />,
      label: 'Signature',
      value: (
        <TruncatedText
          originalText={taskDetailsData?.taskDetails.sig as string}
          textColor={FontColors.WHITE}
          withCopyButton
          dataToolTipId={taskDetailsData?.taskDetails.sig as string}
          dataToolTipContent={taskDetailsData?.taskDetails.sig as string}
        />
      ),
      enable: !!taskDetailsData?.taskDetails.sig,
    },
    {
      icon: <Icon icon={allImgPaths.jsonIcon} />,
      label: 'Payload (JSON)',
      collapsible: true,
      value: (
        <div>
          {taskDetailsData?.taskDetails.payload && (
            <JsonTable
              index={taskDetailsData?.taskDetails.payload.toString() as string}
              jsonData={taskDetailsData?.taskDetails.payload}
            />
          )}
        </div>
      ),
      enable: !!taskDetailsData?.taskDetails.payload,
    },
    {
      icon: <Icon icon={allImgPaths.infoIcon} />,
      label: 'JSON Definition',
      collapsible: true,
      value: (
        <div>
          {taskDetailsData?.taskDetails.definition && (
            <JsonTable
              index={taskDetailsData?.taskDetails.definition.toString() as string}
              jsonData={taskDetailsData?.taskDetails.definition}
            />
          )}
        </div>
      ),
      enable: !!taskDetailsData?.taskDetails.definition,
    },
    {
      icon: <Icon icon={allImgPaths.infoIcon} />,
      label: 'Source Transaction Log',
      collapsible: true,
      value: (
        <div>
          {taskDetailsData?.taskDetails.srcTxLog && (
            <JsonTable
              index={taskDetailsData?.taskDetails.srcTxLog.toString() as string}
              jsonData={taskDetailsData?.taskDetails.srcTxLog}
            />
          )}
        </div>
      ),
      enable: !!taskDetailsData?.taskDetails.srcTxLog,
    },
    {
      icon: <Icon icon={allImgPaths.infoIcon} />,
      label: 'Destination Transaction Log',
      collapsible: true,
      value: (
        <div>
          {taskDetailsData?.taskDetails.destTxLog && (
            <JsonTable
              index={taskDetailsData?.taskDetails.destTxLog.toString() as string}
              jsonData={taskDetailsData?.taskDetails.destTxLog}
            />
          )}
        </div>
      ),
      enable: !!taskDetailsData?.taskDetails.destTxLog,
    },
  ];
};
export default TaskData;
