import { Fragment, useState } from 'react';
import allImgPaths from '../../assets/allImgPaths';
import cn from '../../utils/classnames';
import { Tooltip } from '../Tooltip/Tooltip';
import useCopyToClipboard from './useCopyToClipboard';

export type CopyToClipboardProps = {
  id: string;
  content: string;
  icon?: boolean;
  iconClasses?: string;
  children?: any;
  duration?: number;
};

export const CopyToClipboard = ({
  id,
  content,
  icon = false,
  iconClasses = '',
  children,
  duration = 1000,
}: CopyToClipboardProps) => {
  const copy = useCopyToClipboard();
  const [isCopied, setIsCopied] = useState(false);

  let timeoutId: ReturnType<typeof setTimeout> | null = null;

  const copyToClipboardHandler = (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsCopied(true);
    copy(content);

    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    timeoutId = setTimeout(() => {
      setIsCopied(false);
    }, duration);
  };

  const afterHideHandler = () => {
    isCopied && !icon && setIsCopied(false);
  };

  return (
    <Fragment>
      {icon ? (
        <img
          data-tooltip-id={id + 'copyClipBoardId'}
          src={isCopied ? allImgPaths.gradientTickCircle : allImgPaths.copyIcon}
          className={cn('h-[15px] w-[15px] cursor-pointer', iconClasses)}
          onClick={copyToClipboardHandler}
        />
      ) : (
        <div data-tooltip-id={id + 'copyClipBoardId'} onClick={copyToClipboardHandler}>
          {children}
        </div>
      )}

      <Tooltip
        id={id + 'copyClipBoardId'}
        content={isCopied ? 'Copied' : 'Copy'}
        afterHide={afterHideHandler}
      />
    </Fragment>
  );
};
