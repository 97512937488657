import { useEffect, useRef, useState } from 'react';

type UseTruncateTypes = {
  originalText: string;
};

type TruncateMiddleTypes = {
  text: string;
  maxLength: number;
};

const useTruncate = ({ originalText }: UseTruncateTypes) => {
  const [maxLength, setMaxLength] = useState(originalText?.length);

  const containerRef = useRef<HTMLDivElement>(null);

  const updateMaxLength = () => {
    if (containerRef.current) {
      const containerWidth = containerRef.current.offsetWidth;
      const charWidth = 10;
      const newMaxLength = Math.floor(containerWidth / charWidth);
      setMaxLength(newMaxLength);
    }
  };

  useEffect(() => {
    updateMaxLength();
    window.addEventListener('resize', updateMaxLength);
    return () => {
      window.removeEventListener('resize', updateMaxLength);
    };
  }, []);

  const truncateMiddle = ({ text, maxLength }: TruncateMiddleTypes) => {
    if (text?.length <= maxLength) {
      return text;
    }

    const midpoint = Math.floor(maxLength / 2);
    const start = text?.substring(0, midpoint);
    const end = text?.substring(text.length - midpoint);

    return `${start}...${end}`;
  };

  const truncatedText = truncateMiddle({ text: originalText, maxLength });

  return { truncatedText, containerRef };
};

export default useTruncate;
