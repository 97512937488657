import { useState } from 'react';

import { useApiAccess } from 'contexts/AccessProvider';
import { useTaskDetailsQuery } from 'gql';
import { useParams } from 'react-router-dom';

import TaskData from './components/TaskData';

enum VisibleFieldByPhase {
  submitted = 21,
  Sign = 21,
  Write = 20,
  Read = 20,
}

const useGMPDetails = () => {
  const { id } = useParams();
  const { sessionKey } = useApiAccess();
  const [seeMore, setSeeMore] = useState(false);

  const task = id?.startsWith('0x') ? { hash: id } : { taskId: Number(id) };

  const {
    data: taskDetailsData,
    loading: isLoading,
    error,
  } = useTaskDetailsQuery({
    variables: { sessionKey, ...task },
  });

  const taskData = TaskData({ taskDetailsData });
  const title = 'GMP Info';
  return {
    taskData: taskData.slice(
      0,
      seeMore
        ? taskData.length
        : VisibleFieldByPhase[
            taskDetailsData?.taskDetails?.phase as keyof typeof VisibleFieldByPhase
          ]
    ),
    title,
    isLoading,
    id: taskDetailsData?.taskDetails.taskId || taskDetailsData?.taskDetails.salt,
    error,
    seeMore,
    setSeeMore,
  };
};
export default useGMPDetails;
