import { formatBalance } from '@polkadot/util';
import { AnalogIcon, Identicon } from 'components';
import { Skeleton } from 'components/Skeleton';
import TableColumns from 'components/Table/TableColumns';
import { FontColors } from 'consts';
import { useNavigate } from 'react-router-dom';
import { routes } from 'routes/routesConst';
import { formatBalanceOptionRaw, newFormatBalance } from 'utils';

const AccountColumn = () => {
  const navigate = useNavigate();
  return [
    {
      title: 'Account',
      key: 'publicAddress',
      render(data: any) {
        return (
          <div className="flex gap-[10px] col-span-2 md:col-span-1 cursor-pointer items-center">
            <Identicon value={data[data.columnKey]} size={24} />
            <div className={'flex flex-col overflow-hidden w-full'}>
              <TableColumns
                value={data[data.columnKey]}
                withTruncate
                withCopyButton
                toolTipClass="!cursor-text"
                textColor={FontColors.PURPLE}
                dataTooltipId={data[data.columnKey]}
                dataTooltipContent={data[data.columnKey]}
                onClick={() =>
                  data[data.columnKey] && navigate(`${routes.account}/${data[data.columnKey]}`)
                }
              />
            </div>
          </div>
        );
      },
    },
    {
      title: 'Extrinsics',
      key: 'extrinsicCount',
      render: (data: any) => {
        return (
          <div className="flex flex-row items-center gap-1">
            <TableColumns
              value={data[data.columnKey]}
              classNames={{ beforeIcon: 'h-[9px] w-[9px]', content: 'capitalize text-white' }}
            />
          </div>
        );
      },
    },
    {
      title: 'Balance',
      key: 'free',
      render: (data: any) => (
        <div className="flex flex-row items-center gap-1">
          <Skeleton.Loader>
            <AnalogIcon className="w-5 h-5 shrink-0" />
          </Skeleton.Loader>
          <TableColumns
            value={`${newFormatBalance(data?.free?.replaceAll(',', '') || '0')} ANLOG`}
            classNames={{ content: 'capitalize text-white' }}
            dataTooltipId={data?.free}
            dataTooltipContent={`${formatBalance(
              data?.free?.replaceAll(',', ''),
              formatBalanceOptionRaw
            )} ANLOG`}
            toolTipClass="!w-fit"
          />
        </div>
      ),
    },
  ];
};

export default AccountColumn;
