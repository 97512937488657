import { Tooltip as ReactToolTip } from '@analog/ui/src';
import { Skeleton } from 'components/Skeleton';
import { Link } from 'react-router-dom';
import { routes } from 'routes/routesConst';
import { classes } from 'utils';

import { NetworkStats } from '../../Telemetry';
import { NetworkStatsType } from '../../useTelemetry';
export const NetworkStatsUI = (stats: NetworkStatsType) => {
  return (
    <div className="flex flex-col gap-4 2xl:w-[70%] sm:w-[60%]">
      <span className="md:text-2xl sm:text-xl text-base text-white">Chain Data</span>
      <div className="h-full gap-4 2xl:gap-x-40 xl:gap-x-24 lg:gap-x-12 2xl:gap-y-11 xl:gap-y-11 lg:gap-y-6 justify-between grid sm:grid-cols-3 grid-cols-2 bg-[#010101] rounded-2xl p-4 sm:py-[21px] sm:px-5 border border-[#2B2B2B]">
        {NetworkStats.map(({ icon, title, key, tooltipId, tooltipContent, link }) => (
          <div className="flex flex-col lg:justify-center">
            <ReactToolTip
              place="top-start"
              id={tooltipId || ''}
              content={tooltipContent}
              className="max-w-[340px] md:max-w-[540px] !text-black !rounded-lg z-10 !text-xs !px-[6px] !py-[6px] md:!px-[12px] md:!py-[10px] text-pretty"
            />
            <p className="text-white/70 text-sm whitespace-nowrap flex items-start">
              {icon}
              <Link
                to={link || routes.base}
                className={classes('whitespace-pre-wrap', (tooltipId || link) && 'cursor-pointer')}
                data-tooltip-id={tooltipId}
              >
                {title}
              </Link>
            </p>
            <Skeleton.Loader className="w-20 h-6">
              <div className="relative overflow-hidden pl-8">
                <div
                  className="absolute animate-slide-in text-sm text-white font-medium"
                  key={`title-${new Number(stats[key]).toLocaleString()}`}
                >
                  {new Number(stats[key]).toLocaleString()}
                </div>
                <div
                  className="animate-fade-out text-sm text-white font-medium"
                  key={`title-prev-${new Number(stats[key]).toLocaleString()}`}
                >
                  {new Number(stats[key]).toLocaleString()}
                </div>
              </div>
            </Skeleton.Loader>
          </div>
        ))}
      </div>
    </div>
  );
};
