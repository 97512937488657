import { HashIcon, Icon, Tooltip } from '@analog/ui';
import { formatBalance } from '@polkadot/util';
import allImgPaths from 'assets/allImgPaths';
import { AnalogIcon, Text, TruncatedText } from 'components';
import JsonTable from 'components/common/JsonTable';
import { FontColors } from 'consts';
import { ExtrinsicDetailsQuery } from 'gql';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { routes } from 'routes/routesConst';
import { classes, formatBalanceOptionRaw, newFormatBalance, statusColor, timeAgo } from 'utils';

import { AssetsTransfer } from './AssetsTransfer';

interface OverviewDetailsProps {
  extrinsicDetailsData: ExtrinsicDetailsQuery | undefined;
}
const OverviewDetails = ({ extrinsicDetailsData }: OverviewDetailsProps) => {
  const navigate = useNavigate();
  const failedToolTip =
    extrinsicDetailsData?.extrinsicDetails?.result === 'failed'
      ? extrinsicDetailsData.extrinsicDetails.failed_reason.message
      : '';
  const failedDescription =
    extrinsicDetailsData?.extrinsicDetails?.result === 'failed'
      ? extrinsicDetailsData?.extrinsicDetails?.failed_reason?.type === 'ModuleError'
        ? extrinsicDetailsData?.extrinsicDetails?.failed_reason?.name
        : ''
      : '';
  return [
    {
      icon: <Icon icon={allImgPaths.dataArrowsIcon} />,
      label: 'Extrinsic',
      value: (
        <div className="flex items-center gap-2">{extrinsicDetailsData?.extrinsicDetails?.id}</div>
      ),
    },
    {
      icon: <Icon icon={allImgPaths.clockIcon} />,
      label: 'Timestamp',
      value: extrinsicDetailsData?.extrinsicDetails?.created_at
        ? `${moment
            .utc(extrinsicDetailsData?.extrinsicDetails?.created_at)
            .format('YYYY-MM-DD hh:mm:ss (UTC)')}`
        : '-',
    },
    {
      icon: <Icon icon={allImgPaths.clockIcon} />,
      label: 'Block Time',
      value: extrinsicDetailsData?.extrinsicDetails?.blockTime
        ? `${timeAgo(new Date(extrinsicDetailsData?.extrinsicDetails?.blockTime))}`
        : '-',
    },
    {
      icon: <Icon icon={allImgPaths.hashTagOutlineIcon} />,
      label: 'Block Number',
      value: (
        <div className="flex items-center">
          <Text
            color={FontColors.PURPLE}
            extraClass="cursor-pointer"
            onClick={() =>
              navigate(`${routes.block}/${extrinsicDetailsData?.extrinsicDetails?.block_number}`)
            }
          >
            {extrinsicDetailsData?.extrinsicDetails?.block_number}
          </Text>
          <Icon icon={allImgPaths.greenTickCircleIcon} extraClasses="bg-transparent h-0" />
        </div>
      ),
    },
    {
      icon: <Icon icon={allImgPaths.heart2Icon} />,
      label: 'Life Time',
      value: (
        <div className="flex items-center">
          {typeof extrinsicDetailsData?.extrinsicDetails?.life_time?.data === 'string' ? (
            <div>{extrinsicDetailsData?.extrinsicDetails?.life_time?.data}</div>
          ) : (
            <div className="flex gap-2">
              <Text
                color={FontColors.PURPLE}
                extraClass="cursor-pointer"
                onClick={() =>
                  navigate(
                    `${routes.block}/${extrinsicDetailsData?.extrinsicDetails?.life_time.data.start}`
                  )
                }
              >
                {extrinsicDetailsData?.extrinsicDetails?.life_time?.data?.start}
              </Text>
              <span>-</span>
              <Text
                color={FontColors.PURPLE}
                extraClass="cursor-pointer"
                onClick={() =>
                  navigate(
                    `${routes.block}/${extrinsicDetailsData?.extrinsicDetails?.life_time.data.end}`
                  )
                }
              >
                {extrinsicDetailsData?.extrinsicDetails?.life_time?.data?.end}
              </Text>
            </div>
          )}
        </div>
      ),
    },
    {
      icon: <Icon icon={allImgPaths.hashTagIcon} />,
      label: 'Extrinsic Hash',
      value: (
        <TruncatedText
          originalText={extrinsicDetailsData?.extrinsicDetails?.hash as string}
          textColor={FontColors.WHITE}
          withCopyButton
          width="100%"
          dataToolTipId={extrinsicDetailsData?.extrinsicDetails?.hash}
          dataToolTipContent={extrinsicDetailsData?.extrinsicDetails?.hash}
        />
      ),
    },
    {
      icon: <Icon icon={allImgPaths.phaseIcon} />,
      label: 'Action',
      value: (
        <div className="flex flex-col gap-1 max-h-40">
          <Text extraClass="text-white capitalize bg-[#8247E5] px-[10px] py-1 rounded-full w-fit min-h-fit">
            {extrinsicDetailsData?.extrinsicDetails?.section}(
            {extrinsicDetailsData?.extrinsicDetails?.method})
          </Text>
          {extrinsicDetailsData?.extrinsicDetails?.calls &&
            extrinsicDetailsData?.extrinsicDetails?.calls?.length &&
            extrinsicDetailsData?.extrinsicDetails?.calls?.map((value: any) => {
              return (
                <>
                  <Text extraClass="text-white/80 border border-pink/50 capitalize px-[10px] py-1 rounded-full ml-10 min-h-fit">
                    {value?.section}({value?.method})
                  </Text>
                </>
              );
            })}
        </div>
      ),
    },
    {
      icon: <Icon icon={allImgPaths.senderIcon} />,
      label: 'Sender/Signer',
      value: (
        <HashIcon
          value={extrinsicDetailsData?.extrinsicDetails?.signer}
          className="text-pink cursor-pointer"
          size={20}
          copyable
          dataToolTipId={`${extrinsicDetailsData?.extrinsicDetails?.signer}-signer` as string}
          dataToolTipContent={extrinsicDetailsData?.extrinsicDetails?.signer as string}
          handleNavigate={() =>
            navigate(`${routes.account}/${extrinsicDetailsData?.extrinsicDetails?.signer}`)
          }
        />
      ),
    },
    {
      icon: <Icon icon={allImgPaths.transferIcon} />,
      label: 'Assets Transfer',
      value: <AssetsTransfer transfers={extrinsicDetailsData?.extrinsicDetails?.transfers} />,
      enable: extrinsicDetailsData?.extrinsicDetails?.transfers?.length !== 0,
    },
    {
      icon: <Icon icon={allImgPaths.mathIcon} />,
      label: 'Estimated Fee',
      value: (
        <div className="flex flex-row items-center gap-1">
          <AnalogIcon className="w-5 h-5" />
          <Text
            extraClass="text-white text-sm"
            dataToolTipId={extrinsicDetailsData?.extrinsicDetails?.estimated_fee || ''}
            dataToolTipContent={`${formatBalance(
              extrinsicDetailsData?.extrinsicDetails?.estimated_fee
                ? extrinsicDetailsData?.extrinsicDetails?.estimated_fee.replaceAll(/[^0-9.]/g, '')
                : 0,
              formatBalanceOptionRaw
            )} ANLOG`}
            toolTipClass="!w-fit"
          >
            {newFormatBalance(
              extrinsicDetailsData?.extrinsicDetails?.estimated_fee
                ? extrinsicDetailsData?.extrinsicDetails?.estimated_fee.replaceAll(/[^0-9.]/g, '')
                : '0'
            )}{' '}
            ANLOG
          </Text>
        </div>
      ),
      enable: extrinsicDetailsData?.extrinsicDetails?.estimated_fee !== 'n/a',
    },
    {
      icon: <Icon icon={allImgPaths.walletIcon} />,
      label: 'Used fee',
      value: (
        <div className="flex flex-row items-center gap-1">
          <AnalogIcon className="w-5 h-5" />
          <Text
            extraClass="text-white text-sm"
            dataToolTipId={extrinsicDetailsData?.extrinsicDetails?.used_fee || ''}
            dataToolTipContent={`${formatBalance(
              extrinsicDetailsData?.extrinsicDetails?.used_fee
                ? extrinsicDetailsData?.extrinsicDetails?.used_fee.replaceAll(/[^0-9.]/g, '')
                : 0,
              formatBalanceOptionRaw
            )} ANLOG`}
            toolTipClass="!w-fit"
          >
            {newFormatBalance(
              extrinsicDetailsData?.extrinsicDetails?.used_fee
                ? extrinsicDetailsData?.extrinsicDetails?.used_fee.replaceAll(/[^0-9.]/g, '')
                : '0'
            )}{' '}
            ANLOG
          </Text>
        </div>
      ),
      enable: extrinsicDetailsData?.extrinsicDetails?.used_fee !== 'n/a',
    },
    {
      icon: <Icon icon={allImgPaths.clipboardCloseIcon} />,
      label: 'Nonce',
      value: extrinsicDetailsData?.extrinsicDetails?.nonce,
    },
    {
      icon: <Icon icon={allImgPaths.clipboardTickIcon} />,
      label: 'Result',
      value: (
        <>
          <div
            className={classes(
              `rounded-3xl py-[2px] px-[7px] inline-flex text-ellipsis overflow-hidden whitespace-nowrap w-fit`,
              statusColor(extrinsicDetailsData?.extrinsicDetails?.result || '')
            )}
          >
            <span
              className={
                'text-[10px] rounded-3xl uppercase leading-[18px] text-ellipsis overflow-hidden whitespace-nowrap'
              }
            >
              {extrinsicDetailsData?.extrinsicDetails?.result || '-'}
            </span>
          </div>
          {extrinsicDetailsData?.extrinsicDetails?.result === 'failed' && (
            <div className="flex items-center gap-1">
              <Tooltip
                place="top"
                id={extrinsicDetailsData.extrinsicDetails.id}
                content={failedToolTip}
                className="max-w-[340px] md:max-w-[540px] !text-black !rounded-lg z-10 !text-xs !px-[6px] !py-[6px] md:!px-[12px] md:!py-[10px] text-pretty"
              />
              {failedDescription && <span>({failedDescription})</span>}
              {failedToolTip && (
                <Icon
                  icon={allImgPaths.infoIcon}
                  iconClasses="h-[14px] w-[14px]"
                  extraClasses="bg-transparent h-auto min-w-8 flex-none justify-center cursor-pointer"
                  tooltipId={extrinsicDetailsData.extrinsicDetails.id}
                />
              )}
            </div>
          )}
        </>
      ),
    },
    {
      icon: <Icon icon={allImgPaths.parametersIcon} />,
      label: 'Parameters',
      collapsible: true,
      value: (
        <JsonTable
          index={extrinsicDetailsData?.extrinsicDetails?.id.toString() as string}
          jsonData={extrinsicDetailsData?.extrinsicDetails?.args}
        />
      ),
    },
    {
      icon: <Icon icon={allImgPaths.graphIcon} />,
      label: 'Signature',
      value: (
        <TruncatedText
          originalText={extrinsicDetailsData?.extrinsicDetails?.signature || ''}
          width="80%"
          withCopyButton
          dataToolTipId={extrinsicDetailsData?.extrinsicDetails?.signature || ''}
          dataToolTipContent={extrinsicDetailsData?.extrinsicDetails?.signature || ''}
        />
      ),
    },
  ];
};

export default OverviewDetails;
