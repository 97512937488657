import React, { Dispatch, FC, SetStateAction } from 'react';
import allImgPaths from '../../assets/allImgPaths';
import { checkValidField } from '../../utils/checkValidField';
import { Accordion, Summary } from '../Accordian/Accordian';
import { Card, CardHeader, CardTitle } from '../Card/Card';
import Icon from '../Icon';
import { Skeleton } from '../Skeleton/Skeleton';

export type DetailsListTypes = {
  title: string;
  isLoading: boolean;
  details: {
    icon: React.ReactNode;
    label: string;
    value: string | React.ReactNode;
    collapsible?: boolean;
    enable?: boolean;
  }[];
  className?: string;
  isSeeAll?: boolean;
  seeAll?: Dispatch<SetStateAction<boolean>>;
};

export const DetailsList: FC<DetailsListTypes> = ({
  details,
  title,
  isLoading,
  className,
  isSeeAll,
  seeAll,
}) => {
  return (
    <Skeleton.Provider isLoading={isLoading}>
      <Card className={className}>
        <CardHeader>
          <CardTitle>{title}</CardTitle>
        </CardHeader>
        <div>
          {details.map((item, index) => {
            if (!isLoading && (!checkValidField(item.value) || item.enable === false)) {
              return null;
            }

            return item.collapsible ? (
              <div
                key={index}
                className="flex items-center py-4 border-solid border-t border-darkGray"
              >
                <Accordion>
                  <Summary>
                    <div className="flex items-center gap-2 md:w-4/12 w-6/12">
                      <Skeleton.Loader
                        className="h-10 w-10 !rounded-xl"
                        containerClassName="leading-[0px]"
                      >
                        {item.icon}
                      </Skeleton.Loader>
                      <Skeleton.Loader
                        className="w-[100px] h-[22px]"
                        containerClassName="leading-[0px]"
                      >
                        <div className="text-white text-sm">{item.label}</div>
                      </Skeleton.Loader>
                    </div>
                  </Summary>

                  <div className="p-2 mt-4 bg-[#111010] text-offWhite text-sm break-all">
                    {item.value}
                  </div>
                </Accordion>
              </div>
            ) : (
              <div
                key={index}
                className="grid md:grid-cols-[1fr,2fr] grid-cols-[1fr,1fr] items-center py-4 border-solid border-t border-darkGray"
              >
                <div className="self-start">
                  <div className="flex items-center gap-2.5">
                    <Skeleton.Loader
                      className="h-10 w-10 !rounded-xl"
                      containerClassName="leading-[0px]"
                    >
                      <div>{item.icon}</div>
                    </Skeleton.Loader>
                    <Skeleton.Loader
                      className="w-[100px] h-[22px]"
                      containerClassName="leading-[0px]"
                    >
                      <div className="text-white text-sm">{item.label}</div>
                    </Skeleton.Loader>
                  </div>
                </div>
                <Skeleton.Loader className="w-[100px] h-[22px]" containerClassName="leading-[0px]">
                  <div className="text-white text-sm flex gap-2.5 items-center break-all overflow-x-auto">
                    {item.value}
                  </div>
                </Skeleton.Loader>
              </div>
            );
          })}
        </div>
        {seeAll && (
          <button
            className="pb-4 text-pink w-fit text-sm"
            onClick={() => seeAll((prev: boolean) => !prev)}
          >
            <div className="flex">
              <span>{isSeeAll ? 'See More' : 'See Less'}</span>
              <Icon
                icon={allImgPaths.upArrowIcon}
                iconClasses={`h-4 w-4 ${isSeeAll ? '' : 'rotate-180'}`}
                extraClasses="bg-transparent h-auto min-w-8 flex-none justify-center"
              />
            </div>
          </button>
        )}
      </Card>
    </Skeleton.Provider>
  );
};

DetailsList.displayName = 'DetailsList';
