import { HashIcon, Icon } from '@analog/ui';
import { formatBalance } from '@polkadot/util';
import allImgPaths from 'assets/allImgPaths';
import { AnalogIcon } from 'components';
import TableColumns from 'components/Table/TableColumns';
import { AccountDetailsQuery } from 'gql';
import { formatBalanceOptionRaw, newFormatBalance } from 'utils';

interface AccountDataProps {
  data: AccountDetailsQuery | undefined;
}

const AccountData = ({ data }: AccountDataProps) => {
  return [
    {
      icon: <Icon icon={allImgPaths.hashTagOutlineIcon} />,
      label: 'Wallet Address',
      value: (
        <HashIcon
          value={data?.accountDetails?.publicAddress}
          className="text-white"
          size={20}
          copyable
          dataToolTipId={`${data?.accountDetails?.publicAddress}-address`}
          dataToolTipContent={data?.accountDetails?.publicAddress || ''}
        />
      ),
    },
    {
      icon: <Icon icon={allImgPaths.hashTagOutlineIcon} />,
      label: 'Public Key',
      value: (
        <HashIcon
          value={data?.accountDetails?.address}
          className="text-white"
          size={20}
          copyable
          dataToolTipId={`${data?.accountDetails?.address}-address`}
          dataToolTipContent={data?.accountDetails?.address || ''}
        />
      ),
    },
    {
      icon: <Icon icon={allImgPaths.dollarIcon} />,
      label: 'Balance',
      value: (
        <div className="flex flex-row items-center gap-1">
          <AnalogIcon className="w-5 h-5" />
          <TableColumns
            value={`${newFormatBalance(
              data?.accountDetails?.data?.data?.free?.replaceAll(',', '') || '0'
            )} ANLOG`}
            classNames={{ content: 'capitalize text-white' }}
            dataTooltipId={data?.accountDetails?.data?.data?.free}
            dataTooltipContent={`${formatBalance(
              data?.accountDetails?.data?.data?.free?.replaceAll(',', ''),
              formatBalanceOptionRaw
            )} ANLOG`}
            toolTipClass="!w-fit"
          />
        </div>
      ),
    },
    {
      icon: <Icon icon={allImgPaths.dollarIcon} />,
      label: 'Reserved',
      value: (
        <div className="flex flex-row items-center gap-1">
          <AnalogIcon className="w-5 h-5" />
          <TableColumns
            value={`${newFormatBalance(
              data?.accountDetails?.data?.data?.reserved?.replaceAll(',', '') || '0'
            )} ANLOG`}
            classNames={{ content: 'capitalize text-white' }}
            dataTooltipId={data?.accountDetails?.data?.data?.reserved}
            dataTooltipContent={`${formatBalance(
              data?.accountDetails?.data?.data?.reserved?.replaceAll(',', ''),
              formatBalanceOptionRaw
            )} ANLOG`}
            toolTipClass="!w-fit"
          />
        </div>
      ),
      enable: data?.accountDetails.data.data.reserved !== '0',
    },
    {
      icon: <Icon icon={allImgPaths.dollarIcon} />,
      label: 'Frozen',
      value: (
        <div className="flex flex-row items-center gap-1">
          <AnalogIcon className="w-5 h-5" />
          <TableColumns
            value={`${newFormatBalance(
              data?.accountDetails?.data?.data?.frozen?.replaceAll(',', '') || '0'
            )} ANLOG`}
            classNames={{ content: 'capitalize text-white' }}
            dataTooltipId={data?.accountDetails?.data?.data?.frozen}
            dataTooltipContent={`${formatBalance(
              data?.accountDetails?.data?.data?.frozen?.replaceAll(',', ''),
              formatBalanceOptionRaw
            )} ANLOG`}
            toolTipClass="!w-fit"
          />
        </div>
      ),
      enable: data?.accountDetails.data.data.frozen !== '0',
    },
    {
      icon: <Icon icon={allImgPaths.clipboardCloseIcon} />,
      label: 'Nonce',
      value: data?.accountDetails.data.nonce,
    },
  ];
};
export default AccountData;
